import React from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  NavLink,
  // Link,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../../Assests/Images/logo.png";
import styles from "../Header/Header.module.css";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const handleLogoClick = () => {
    if (!isAuthenticated) {
      navigate("/");
    }
  };

  return (
    <div className={styles.mainHeader}>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand onClick={() => handleLogoClick()}>
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Brand>
          {!isAuthenticated && (
            <>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto"></Nav>
                <Nav>
                  <Link to="/" className={styles.activeTab}>
                    {t("HOME")}
                  </Link>
                  <Link to="" className={styles.inactiveTab}>
                    {t("ABOUTUS")}
                  </Link>
                  <Link to="/signin" className={styles.signOut}>
                    {t("SIGNIN/SIGNUP")}
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </>
          )}
        </Container>
      </Navbar>
    </div>
  );
}
export default Header;
