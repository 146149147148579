import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../Assests/Images/edit.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Table, Image, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import AddEvent from "../Event/AddEvent";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import IndependicareApi from "../../Helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { clearUserState } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import style from "./Event.module.css";
import useDebounce from "../../Helpers/Debounce";
import Loader from "../LoaderComponent/LoaderComponent";
import { DATE_FORMATES } from "../../Utils/Constant";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import styles from "./Event.module.css";
import {
  convertUTCToLocal,
  convertUTCToLocalDatetime,
} from "../../Utils/Common";
import Confirm from "../../Common/Confirm";
import dayjs from "dayjs";
import { convertUTCTimeToLocalTime } from "../../Utils/Common";

const Event = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [eventData, setEventData] = useState([]);
  const [eventItem, setEventItem] = useState("");
  const [eventType, setEventTpe] = useState("event");
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [addedCaregiverListCount, setAddedCaregiverListCount] = useState(0);

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const pageLimit = 10;

  const debouncedSearch = useDebounce((term) => {
    getEventList(1, term, 1);
    if (term == "") {
      setCurrentPage(1);
    }
  }, 500);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getEventList(pageNumber, searchQuery);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEventItem(null);
  };

  const handleShowModal = (item = null) => {
    setShowModal(true);
  };

  const getEventList = (page, searchTerm) => {
    setIsLoading(true);
    const userCurrentPage = page;
    const search = searchTerm;
    IndependicareApi.getEventList(
      userCurrentPage,
      eventType,
      token,
      search
    ).then((res) => {
      setSearchQuery(searchTerm);
      setCurrentPage(page);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        let eventList = res?.data?.eventList;
        setAddedCaregiverListCount(res?.data?.count);
        if (Array.isArray(eventList)) {
          eventList = eventList.map((event) => {
            if (event.date) {
              // event.date = moment(event.date).format(DATE_FORMATES.DD_MM_YY);
            }
            return event;
          });
        }
        setEventData(eventList);
        setIsLoading(false);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
        setIsLoading(false);
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
        setIsLoading(false);
      } else {
        Toster(t(responseCode), "error");
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getEventList(currentPage, searchQuery);
  }, []);

  const deleteEvent = (eventId) => {
    setEventIdToDelete(eventId);
    setTitleText(t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_EVENT"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const handleLocationStatusUpdate = (eventItem) => {
    setIsLoading(true);
    IndependicareApi.eventStatusUpdate(eventItem.id, token).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("UPDATE_STATUS_SUCCESS_EVENT"), "success");
          getEventList(1, searchQuery);
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const deleteContent = async () => {
    setIsLoading(true);
    try {
      const response = await IndependicareApi.deleteEvent(
        eventIdToDelete,
        token
      );
      const responseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        getEventList(1, searchQuery);
        Toster(t("EVENT_DELETED_SUCCESSFULLY"), "success");
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      Toster(t("ERROR_DELETING_EVENT"), "error");
    } finally {
      setIsLoading(false);
      setIsShowConfirm(false);
    }
  };

  const editEvent = (item) => {
    setEventItem(item);
    handleShowModal();
  };

  const activeInactiveFunction = (item) => {
    setEventItem(item);
    if (item.is_active === 1) {
      setTitleText(t("EVENT_INACTIVE"));
    } else {
      setTitleText(t("EVENT_ACTIVE"));
    }
    setIsDeleting(false);
    setIsShowConfirm(true);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    isDeleting ? deleteContent() : handleLocationStatusUpdate(eventItem);
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100 w-100">
      {isLoading && <Loader />}
      <div>
        <div className="">
          <Container>
            <Row className="align-items-center">
              <Col
                className="setSearchInputField"
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                <CustomSearchField
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearch(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={handleShowModal}
                  >
                    {t("ADD_EVENT")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className={styles?.eventTable}>
              <div className="setTableFromBottom">
                {eventData.length > 0 ? (
                  <Table className={styles.customTable} striped>
                    <thead className={styles.tableHeadProperty}>
                      <tr>
                        <th>{t("DATE")}</th>
                        <th>{t("TIME")}</th>
                        <th>{t("EVENT_TYPE")}</th>
                        <th>{t("PERSON")}</th>
                        <th className={style.reminder}>{t("REMINDER")}</th>
                        <th>{t("STATUS")}</th>
                        <th
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {t("ACTION")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {convertUTCToLocalDatetime(
                              item.date,
                              item.time,
                              "MM/DD/YYYY"
                            )}
                            {/* {moment(item.date, DATE_FORMATES.DD_MM_YY).format(
                              DATE_FORMATES.MM_DD_YY
                            )} */}
                            {/* {moment.utc(item.date).format("MM/DD/YYYY")} */}
                          </td>
                          <td>
                            {moment(
                              convertUTCToLocal(item?.time),
                              "hh:mm:ss A"
                            ).format("hh:mm A")}
                          </td>

                          <td>{item.event_title}</td>
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className=""
                          >
                            <div className="tdContentWrapper">
                              {item.person_names}
                            </div>
                          </td>
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className=""
                          >
                            <div className="tdContentWrapper">
                              {item.reminder
                                ? item.reminder
                                : item.custom_reminder}
                            </div>
                          </td>
                          <td>
                            {item.is_active === 1 ? t("ACTIVE") : t("INACTIVE")}
                          </td>
                          <td>
                            <Dropdown className="setParentDropdown">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="custom-dropdown-toggle p-0 border-0"
                              >
                                <MoreHorizIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => editEvent(item)}
                                >
                                  <Dropdown.Item>{t("EDIT")}</Dropdown.Item>
                                  <Image src={edit} className="dropdownIcons" />
                                </div>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => deleteEvent(item.id)}
                                >
                                  <Dropdown.Item>{t("DELETE")}</Dropdown.Item>
                                  <DeleteOutlineIcon className="iconColor" />
                                </div>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => {
                                    activeInactiveFunction(item);
                                  }}
                                >
                                  <Dropdown.Item>{`${
                                    item.is_active == 1
                                      ? t("INACTIVE")
                                      : t("ACTIVE")
                                  }`}</Dropdown.Item>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className={style.noRecord}>
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Pagination
        count={addedCaregiverListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />

      <AddEvent
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getEventList={() => {
          getEventList(1, searchQuery);
        }}
        eventItem={eventItem}
      />
    </div>
  );
};
export default Event;
