import React, { useEffect } from "react";
import styles from "../StakeholderDetail/StakeholderDetail.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import { DropdownButton, Dropdown, Button } from "react-bootstrap";
import Edit from "../../Assests/Images/edit_img.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import dotIcon from "../../Assests/Images/dotIcon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";
import Toster from "../../Toster/Toster";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import IndependicareApi from "../../Helpers/Api";
import { clearUserState } from "../../redux/slices/userSlice";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import moment from "moment";
import { DATE_FORMATES } from "../../Utils/Constant";
import Loader from "../LoaderComponent/LoaderComponent";
import AddEvent from "../Event/AddEvent";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import AddDailyEvents from "../DailyEvents/AddDailyEvents";
import {
  convertUTCToLocal,
  convertUTCToLocalDatetime,
} from "../../Utils/Common";

function Event({
  eventList,
  eventCount,
  dailyEventList,
  dailyEventCount,
  id,
  type,
  getStakeholderDetails,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const [showModal, setShowModal] = useState(false);
  const [showModalForDailyEvent, setShowModalForDailyEvent] = useState(false);
  const [event, setEvent] = useState(eventList);
  const [dailyEvent, setDailyEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDailyEvent, setCurrentPageDailyEvent] = useState(1);
  const [eventItem, setEventItem] = useState("");
  const [dailyEventItem, setDailyEventItem] = useState("");
  const [tabKey, setTabKey] = useState("event");

  const pageLimit = 10;

  const handleCloseModal = () => {
    setShowModal(false);
    setEventItem(null);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModalForDailyEvent = () => {
    setShowModalForDailyEvent(false);
    setDailyEventItem(null);
  };

  const handleShowModalForDailyEvent = () => {
    setShowModalForDailyEvent(true);
  };

  const onPageChange = (pageNumber, paginationType) => {
    setIsLoading(true);
    const payLoads = {
      id,
      page_no: pageNumber,
      pagination_type: paginationType,
    };
    getList(payLoads);

    if (paginationType === "event") {
      setCurrentPage(pageNumber);
    } else {
      setCurrentPageDailyEvent(pageNumber);
    }
  };

  const getList = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getStakeholderDetails(token, payLoads?.id, payLoads).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (response?.code === STATUS_CODE.SUCCESS) {
          if (payLoads.pagination_type === "event") {
            let eventList = response?.data?.eventList;

            if (Array.isArray(eventList)) {
              eventList = eventList.map((event) => {
                if (event.date) {
                  // event.date = moment(event.date).format(
                  //   DATE_FORMATES.DD_MM_YY
                  // );
                }
                return event;
              });
            }
            setEvent(eventList);

            getStakeholderDetails();
          } else {
            let dailyEventList = response?.data?.dailyEventList;

            if (Array.isArray(dailyEventList)) {
              dailyEventList = dailyEventList.map((event) => {
                return event;
              });
              setDailyEvent(dailyEventList);
              getStakeholderDetails();
            }
          }
        } else if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };

  const handleTabSelect = (key) => {
    setTabKey(key);
    if (key === "event") {
      setCurrentPage(currentPage);
      onPageChange(1, "event");
    } else {
      setCurrentPageDailyEvent(currentPageDailyEvent);
      onPageChange(1, "daily-event");
    }
  };

  const updateEvent = (item) => {
    setEventItem(item);
    handleShowModal();
  };

  const updateDailyEvent = (item) => {
    setDailyEventItem(item);
    handleShowModalForDailyEvent();
  };

  const addEvents = (e) => {
    if (tabKey === "dailyevent") {
      handleCloseModal();
      handleShowModalForDailyEvent();
    } else if (tabKey === "event") {
      handleCloseModalForDailyEvent();
      handleShowModal();
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={`${styles.addButton} modelButtonPropwerty`}>
        <CustomButton
          value={
            tabKey === "dailyevent" ? t("ADD_DAILY_EVENT") : t("ADD_EVENT")
          }
          className={`${styles.eventButton} modalTextButton`}
          type="button"
          onClick={(e) => {
            addEvents(e);
          }}
        />
      </div>
      <Table striped responsive>
        <tbody>
          <Tabs
            defaultActiveKey="event"
            id="justify-tab-example1"
            className="mb-3"
            justify
            onSelect={handleTabSelect}
          >
            <Tab eventKey="event" title="Event">
              {event && event.length > 0 ? (
                <Table striped>
                  <thead className={styles.thead}>
                    <tr>
                      <th>{t("DATE")}</th>
                      <th>{t("TIME")}</th>
                      <th>{t("EVENT_TYPE")}</th>
                      <th className={styles.reminderText}>{t("REMINDER")}</th>
                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {event.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {convertUTCToLocalDatetime(
                              item.date,
                              item.time,
                              "MM/DD/YYYY"
                            )}
                            {/* {moment(item.date, DATE_FORMATES.DD_MM_YY).format(
                              DATE_FORMATES.MM_DD_YY
                            )} */}
                            {/* {moment.utc(item.date).format("MM/DD/YYYY")} */}
                          </td>
                          <td>
                            {moment(
                              convertUTCToLocal(item?.time),
                              "hh:mm:ss A"
                            ).format("hh:mm A")}
                          </td>
                          <td>{item.event_type_title}</td>
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            <div className="tdContentWrapper">
                              {item.reminder
                                ? item.reminder
                                : item.custom_reminder}
                            </div>
                          </td>
                          <td>
                            {item.update_permission == "true" ? (
                              <DropdownButton
                                id="dropdown"
                                className={styles.customBtn}
                                title={<img src={dotIcon} alt="Logo" />}
                              >
                                <Dropdown.Item
                                  className={styles.editIcon}
                                  onClick={() => {
                                    updateEvent(item);
                                  }}
                                >
                                  <span>{t("EDIT")}</span>{" "}
                                  <img src={Edit} alt="Logo" className="logo" />
                                </Dropdown.Item>
                              </DropdownButton>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className={styles.noRecord}>
                  <p>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}
              <Pagination
                count={eventCount}
                pageLimit={pageLimit}
                currentPage={currentPage}
                // onPageChange={onPageChange}
                onPageChange={(page) => onPageChange(page, "event")}
              />

              <AddEvent
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                getEventList={() => {
                  setCurrentPage(1);
                  getList({
                    id: id,
                    page_no: 1,
                    pagination_type: "event",
                  });
                }}
                // pageNo={currentPage}
                eventItem={eventItem}
                id={id}
                type={"caregiver"}
              />
            </Tab>
            <Tab eventKey="dailyevent" title="Daily Event">
              {dailyEvent && dailyEvent.length > 0 ? (
                <Table striped>
                  <thead className={styles.thead}>
                    <tr>
                      <th>{t("TIME")}</th>
                      <th>{t("DAILY_EVENT_TYPE")}</th>
                      <th className={styles.reminderText}>{t("REMINDER")}</th>
                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dailyEvent.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {moment(
                              convertUTCToLocal(item?.time),
                              "hh:mm:ss A"
                            ).format("hh:mm A")}
                          </td>
                          <td>{item.event_type_title}</td>
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            <div className="tdContentWrapper">
                              {item.reminder
                                ? item.reminder
                                : item.custom_reminder}
                            </div>
                          </td>
                          <td>
                            {item.update_permission == "true" ? (
                              <DropdownButton
                                id="dropdown"
                                className={styles.customBtn}
                                title={
                                  <img
                                    src={dotIcon}
                                    alt="Logo"
                                    style={{ cursor: "pointer" }}
                                  />
                                }
                              >
                                <Dropdown.Item
                                  className={styles.editIcon}
                                  onClick={() => {
                                    updateDailyEvent(item);
                                  }}
                                >
                                  <span>{t("EDIT")}</span>{" "}
                                  <img src={Edit} alt="Logo" className="logo" />
                                </Dropdown.Item>
                              </DropdownButton>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className={styles.noRecord}>
                  <p>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}
              {dailyEvent.length > 0 && (
                <Pagination
                  count={dailyEventCount}
                  pageLimit={pageLimit}
                  currentPage={currentPageDailyEvent}
                  // onPageChange={onPageChangeDailyEvent}
                  onPageChange={(page) => onPageChange(page, "daily-event")}
                />
              )}
              <AddDailyEvents
                showModal={showModalForDailyEvent}
                handleCloseModal={handleCloseModalForDailyEvent}
                getEventList={() => {
                  setCurrentPageDailyEvent(1);
                  getList({
                    id: id,
                    page_no: 1,
                    pagination_type: "daily-event",
                  });
                }}
                // pageNo={currentPageDailyEvent}
                eventItem={dailyEventItem}
                id={id}
                type={"caregiver"}
              />
            </Tab>
          </Tabs>
        </tbody>
      </Table>
    </>
  );
}
export default Event;
