import React, { useEffect } from "react";
import { useState } from "react";
import styles from "../StakeholderDetail/StakeholderDetail.module.css";
import { useTranslation } from "react-i18next";
import Table from "react-bootstrap/Table";
import moment from "moment";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import Loader from "../LoaderComponent/LoaderComponent";

function Devices({ deviceList, id, count }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState(deviceList);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const tdAlignClass = { flex: 1, alignContent: "center" };

  const pageLimit = 10;
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payLoads = {
      id: id,
      page_no: pageNumber,
      pagination_type: "device",
    };
    getList(payLoads);
  };

  const getList = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getStakeholderDetails(token, payLoads?.id, payLoads).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (response?.code === STATUS_CODE.SUCCESS) {
          setList(response?.data?.deviceList);
        } else if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };

  return (
    <div className="d-flex flex-column justify-content-between h-111 w-100">
      {isLoading && <Loader />}
      {list.length > 0 ? (
        <Table striped responsive>
          <thead className={styles.thead}>
            <tr>
              <th>{t("DEVICE_TITLE")}</th>
              {/* <th>{t("DEVICE_ID")}</th> */}
              <th>{t("DEVICE_SERIAL_NUMBER")}</th>
              <th>{t("PERSON")}</th>
              <th>{t("DEVICE_MAINTENANCE_DATE")}</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr key={index}>
                  {/* <td>{item.device_name}</td> */}
                  <td
                    style={{
                      ...tdAlignClass,
                    }}
                    className="tdtextNotes tdtext"
                  >
                    <div className="tdContentWrapper">{item.device_title}</div>
                  </td>
                  {/* <td>{item.device_id}</td> */}
                  {/* <td>{item.device_serial_number}</td> */}
                  <td
                    style={{
                      ...tdAlignClass,
                    }}
                    className="tdtextNotes tdtext"
                  >
                    <div className="tdContentWrapper">
                      {item.device_serial_number}
                    </div>
                  </td>
                  {/* <td>{item.person_names}</td> */}
                  <td
                    style={{
                      ...tdAlignClass,
                    }}
                    className="tdtextNotes tdtext"
                  >
                    <div className="tdContentWrapper">{item.person_names}</div>
                  </td>
                  <td className={styles.center}>
                    {moment(item.maintenance_date)?.format("DD/MM/YY")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div className={styles.noRecord}>
          <p>{t("NO_RECORD_FOUND")}</p>
        </div>
      )}
      <Pagination
        count={count}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
}

export default Devices;
