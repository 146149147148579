import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import Loader from "../../LoaderComponent/LoaderComponent";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { clearUserState } from "../../../redux/slices/userSlice";
import Toster from "../../../Toster/Toster";
import styles from "../LocationType/LocationType.module.css";
import debounce from "debounce";
import { ACTIVE_TYPE } from "../../../Utils/Constant";
import CustomSearchField from "../../CustomComponent/CustomSearchField/CustomSearchField";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../../Assests/Images/edit.png";
import AddSubLocationTypeModel from "./AddSubLocationTypeModel";
import Confirm from "../../../Common/Confirm";
import {
  CustomSelectFieldsLocation,
  CustomSelectFieldsModelForm,
} from "../../CustomComponent/CustomInputFields/CustomInputField";
import { useForm } from "react-hook-form";

export default function LocationType() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userToken = useSelector((state) => state.user.userToken);
  const { t } = useTranslation();
  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    setIsDeleting(false);
    setFilterId("");
    setValue("locations", "");
  };
  const { register, formState, setValue } = useForm({
    mode: "onChange",
  });
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [location, setLocation] = useState([]);
  const [subLocation, setSubLocation] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [locationId, setLocationId] = useState();
  const [filterId, setFilterId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const handleGetSubLocationData = (searchTerm, id) => {
    setIsLoading(true);
    IndependicareApi.fetchSubLocationType(userToken, searchTerm, id).then(
      (response) => {
        setIsLoading(false);
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/");
        } else {
          setSubLocation(response?.data?.subloactionType);
        }
      }
    );
  };

  const handleGetLocationData = () => {
    setIsLoading(true);
    IndependicareApi.fetchLocationType(userToken, "").then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
      } else {
        setLocation(response?.data?.loaction_type);
      }
    });
  };

  const formattedOptions = location.map((location) => ({
    id: location.id,
    name: location.title,
  }));

  useEffect(() => {
    handleGetLocationData();
  }, []);

  useEffect(() => {
    handleGetSubLocationData(searchTerm, filterId);
  }, []);

  const debouncedLocationData = useCallback(
    debounce((searchTerm) => {
      handleGetSubLocationData(searchTerm, filterId);
    }, 500),
    []
  );

  const handleInputChange = (val) => {
    setFilterId("");
    setValue("locations", "");
    debouncedLocationData(val);
  };

  const handleDeleteShow = () => {
    setTitleText(t("SURE_DELETE_SUB_LOCATION"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const handleStatusShow = (activeType) => {
    if (activeType === 1) {
      setTitleText(t("SUB_LOCATION_INACTIVE"));
    } else {
      setTitleText(t("SUB_LOCATION_ACTIVE"));
    }
    setIsDeleting(false);
    setIsShowConfirm(true);
  };

  const handleSubLocationDelete = (id) => {
    setIsLoading(true);
    IndependicareApi.deleteSubLocationType(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("SUB_LOCATION_DELETED_SUCCESSFULLY"), "success");
          handleGetSubLocationData(searchTerm, "");
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const handleSubLocationStatusUpdate = (id) => {
    setIsLoading(true);
    IndependicareApi.updateStatusSubLocation(userToken, id).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("SUB_LOCATION_UPDATE_STATUS_SUCCESS"), "success");
          handleGetSubLocationData(searchTerm, "");
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const handleConfirm = () => {
    setIsLoading(true);
    isDeleting
      ? handleSubLocationDelete(locationId)
      : handleSubLocationStatusUpdate(locationId);
  };

  const handleLocationChange = (e) => {
    setSearchTerm("");
    const id = e.target.value;
    id && setLocationId(id);
    setFilterId(id);
    handleGetSubLocationData("", id);
  };

  useEffect(() => {
    if (filterId) {
      handleGetSubLocationData("", filterId);
    }
  }, [filterId]);

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.seetingRightSidebar}>
        <div className={styles.sechSeetingmain}>
          <Container>
            <Row className="align-items-center">
              <Col className="setSearchInputField">
                <div className={styles.serchTabs}>
                  <CustomSearchField
                    setSearchQuery={(val) => {
                      setSearchTerm(val);
                      handleInputChange(val);
                    }}
                    value={searchTerm}
                  />

                  <CustomSelectFieldsLocation
                    PlaceHolder={t("SELECT_LOCATION")}
                    RegisterName="locations"
                    register={register}
                    options={formattedOptions}
                    formState={formState}
                    value={filterId}
                    onChange={(e) => handleLocationChange(e)}
                    className={`${styles.addDeviceProperty} ${styles.langSelect}`}
                  />
                </div>
              </Col>

              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={() => {
                      handleShow();
                      setIsEdit(false);
                      setLocationId();
                    }}
                  >
                    {t("ADD_NEW")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {subLocation?.length !== 0 ? (
          <Container>
            <div className={styles.settingPermissionTable}>
              <Table className="customTable" striped>
                {isLoading && <Loader />}
                <thead>
                  <tr>
                    <th className={styles.settingBackground}>
                      {t("SUB_LOCATION")}
                    </th>
                    <th className={styles.settingBackground}>
                      {t("LOCATION")}
                    </th>
                    <th className={styles.settingBackground}>{t("STATUS")}</th>
                    <th className={styles.settingBackgroundAction}>
                      {t("ACTION")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subLocation?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.caregiverSourceText}>
                          {item.title}
                        </td>
                        <td className={styles.caregiverSourceText}>
                          {item.location_title}
                        </td>
                        <td className={styles.caregiverSourceText}>
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? t("ACTIVE")
                            : t("INACTIVE")}
                        </td>
                        <td className={styles.settingDotImage}>
                          <Dropdown className={styles.setEventDropdown}>
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                className={styles.caregiverdropdownItem}
                                onClick={() => {
                                  handleShow();
                                  setIsEdit(true);
                                  setLocationId(item.id);
                                }}
                              >
                                <span>{t("EDIT")}</span>
                                <Image src={edit} className="dropdownIcons" />
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                className={styles.caregiverdropdownItem}
                                onClick={() => {
                                  handleDeleteShow();
                                  setLocationId(item.id);
                                }}
                              >
                                <span>{t("DELETE")}</span>
                                <DeleteOutlineIcon className="iconColor" />
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => {
                                  handleStatusShow();
                                  setLocationId(item.id);
                                  handleStatusShow(item.is_active);
                                }}
                                className={styles.caregiverdropdownItem}
                              >
                                <span>
                                  {item.is_active === 1
                                    ? t("INACTIVE")
                                    : t("ACTIVE")}
                                </span>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Container>
        ) : (
          !isLoading &&
          subLocation.length == 0 && (
            <p className={styles.listRecoredText}>{t("RECORD_NOT_FOUND")}</p>
          )
        )}
      </div>

      <AddSubLocationTypeModel
        show={show}
        handleClose={handleClose}
        handleGetData={handleGetSubLocationData}
        isEdit={isEdit}
        locationId={locationId}
        setLocationId={setLocationId}
        searchTerm={searchTerm}
        formattedOptions={formattedOptions}
      />

      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />
    </>
  );
}
