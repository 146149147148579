import React, { useEffect, useState } from "react";
import styles from "../StakeholderDetail/StakeholderDetail.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import Loader from "../LoaderComponent/LoaderComponent";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Edit from "../../Assests/Images/edit_img.svg";
import dotIcon from "../../Assests/Images/dotIcon.svg";
import AddTag from "../TagList/AddTag";

function Tag({ id, tagList, count }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const [list, setList] = useState(tagList);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [editTagItem, setEditTagItem] = useState("");

  const pageLimit = 10;
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payLoads = {
      id: id,
      page_no: pageNumber,
      pagination_type: "tag",
    };
    getList(payLoads);
  };

  const getList = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getStakeholderDetails(token, payLoads?.id, payLoads).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (response?.code === STATUS_CODE.SUCCESS) {
          setList(response?.data?.tagList);
        } else if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };

  const handleCloseModal = () => {
    // setItemData(null);
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const callTagDetails = (id) => {
    const payLoads = {
      tagId: id,
    };

    IndependicareApi.getTagDetails(token, id, payLoads).then((res) => {
      if (res?.code === STATUS_CODE.SUCCESS) {
        // setItemData(res?.data);
        setEditTagItem(res?.data);

        handleShowModal();
      } else {
        // errorHandling(res?.data?.code);
      }
    });
  };

  return (
    <div className="d-flex flex-column justify-content-between h-111 w-100">
      {isLoading && <Loader />}
      <div>
        {list.length > 0 ? (
          <Table striped responsive>
            <thead className={styles.thead}>
              <tr>
                <th>{t("TAG_ID")}</th>
                <th>{t("PERSON_NAME")}</th>
                {/* <th>{t("TAG_TYPE")}</th> */}
                <th>{t("LOCATION_TYPE")}</th>
                <th>{t("SUB_LOCATION_TYPE")}</th>
                {/* <th className="d-flex justify-content-start">{t("NOTES")}</th> */}
                <th className={styles.notesText}>{t("NOTES")}</th>
                <th>{t("ACTION")}</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{item.tag_uniq_id}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">{item.tag_uniq_id}</div>
                    </td>
                    {/* <td>{item.person_name}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">{item.person_name}</div>
                    </td>
                    {/* <td>{item.tag_type ? item.tag_type : t("NA")}</td> */}
                    <td>{item.location_type}</td>
                    <td>{item.sub_location_type}</td>
                    {/* <td className="d-flex justify-content-start">{item.notes}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">
                        {item.notes ? item.notes : t("NA")}
                      </div>
                    </td>
                    <td>
                      {item.update_permission == "true" ? (
                        <DropdownButton
                          id="dropdown"
                          className={styles.customBtn}
                          title={
                            <img
                              src={dotIcon}
                              alt="Logo"
                              style={{ cursor: "pointer" }}
                            />
                          }
                        >
                          <Dropdown.Item
                            className={styles.editIcon}
                            onClick={() => {
                              callTagDetails(item?.id);
                            }}
                          >
                            <span>{t("EDIT")}</span>{" "}
                            <img src={Edit} alt="Logo" className="logo" />
                          </Dropdown.Item>
                        </DropdownButton>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className={styles.noRecord}>
            <p>{t("NO_RECORD_FOUND")}</p>
          </div>
        )}
      </div>
      <Pagination
        count={count}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <AddTag
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        itemData={editTagItem}
        getList={() => {
          const payLoads = {
            id: id,
            page_no: 1,
            pagination_type: "tag",
          };
          getList(payLoads);
        }}
        id={id}
        type={"caregiver"}
      />
    </div>
  );
}
export default Tag;
