import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { CustomInputFieldsModelForm } from "../CustomComponent/CustomInputFields/CustomInputField";
import styles from "./AddSite.module.css";
// import Tooltip from "@mui/material/Tooltip";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { CustomDatePicker } from "../CustomComponent/CustomInputFields/CustomInputField";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import IndependicareApi from "../../Helpers/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../LoaderComponent/LoaderComponent";
import Toster from "../../Toster/Toster";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";
import { DATE_FORMATES, LIST_TYPE } from "../../Utils/Constant";
import { format } from "date-fns";
import CustomSearchField from "../CustomComponent/CustomSearchField/CustomSearchField";
import useDebounce from "../../Helpers/Debounce";
import { debounce } from "lodash";

function Addsite({
  showModal,
  handleCloseModal,
  siteList,
  currentPage,
  siteItem,
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [selectDate, setSelectDate] = useState(new Date());
  // const [deviceList, setDeviceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDevice, setSelectedDevice] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [devices, setDevices] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [deviceNameField, setDeviceNameField] = useState("");
  const [sendDevice, setSendDevice] = useState([]);
  const [siteUID, setSiteUID] = useState("");

  // const [tagList, setTagList] = useState([]);
  // const [selectedTag, setSelectedTag] = useState([]);
  // const [tagOptions, setTagOptions] = useState([]);s
  // const [tagName, setTagName] = useState("");
  // const [tagNameField, setTagNameField] = useState("");
  // const [sendSelectedTag, setSendSelectedTag] = useState([]);

  const [getPerson, setGetPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [personOption, setPersonOption] = useState([]);
  const [person, setPerson] = useState([]);
  const [personName, setPersonName] = useState([]);
  const [showPerson, setShowPerson] = useState(false);
  const [showDevice, setShowDevice] = useState(false);
  // const [showTagModel, setShowTagModel] = useState(false);

  const [selectedLabelNames, setSelectedLabelNames] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [siteIdToUpdate, setSiteIdToUpdate] = useState("");

  const [currentPageForPerson, setCurrentPageForPerson] = useState(1);
  const [addedCaregiverListCountPerson, setAddedCaregiverListCountPerson] =
    useState(0);
  const pageLimit = 10;

  const [currentPageForDevice, setCurrentPageForDevice] = useState(1);
  const [addedListCountDevice, setAddedListCountDevice] = useState(0);

  // const [currentPageForTag, setCurrentPageForTag] = useState(1);
  // const [addedListCountTag, setAddedListCountTag] = useState(0);

  const [isLoadMore, setIsLoadMore] = useState(false);
  const listInnerRef = useRef();

  const handleScroll = () => {
    if (!isLoadMore) return;

    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        let roundedNumber = Math.ceil(
          addedCaregiverListCountPerson / pageLimit
        );
        if (currentPageForPerson >= roundedNumber) {
          setIsLoadMore(false);
        } else {
          onPageChangePerson(currentPageForPerson + 1);
        }
      }
    }
  };

  const handleScrollDevice = debounce(() => {
    if (!isLoadMore) return;

    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        // onPageChangeDevices(currentPageForDevice + 1);

        let roundedNumber = Math.ceil(addedListCountDevice / pageLimit);

        if (currentPageForDevice >= roundedNumber) {
          setIsLoadMore(false);
        } else {
          onPageChangeDevices(currentPageForDevice + 1);
        }
      }
    }
  }, 200);

  // const handleScrollTags = () => {
  //   if (!isLoadMore) return; // Prevent further API calls if there's no more data to load

  //   if (listInnerRef.current) {
  //     const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
  //     if (scrollTop + clientHeight >= scrollHeight - 5) {
  //       onPageChangeTag(currentPageForTag + 1);
  //     }
  //   }
  // };

  const onPageChangePerson = (pageNumber) => {
    setCurrentPageForPerson(pageNumber);
    getPersonList(pageNumber, searchQuery);
  };

  const onPageChangeDevices = (pageNumber) => {
    setCurrentPageForDevice(pageNumber);
    getDeviceList(pageNumber, searchQuery);
  };
  // const onPageChangeTag = (pageNumber) => {
  //   // setCurrentPageForTag(pageNumber);
  //   getDeviceList(pageNumber, searchQuery);
  // };

  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.userToken);
  const usertype = useSelector((state) => state.user.userType);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClosePerson = () => {
    setSearchQuery("");
    setShowPerson(false);
  };
  const handleShowPerson = () => setShowPerson(true);
  const multiSelectRef = useRef(null);

  const getDeviceList = async (currentPageForDevice, searchQuery) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.getDeviceList(
        currentPageForDevice,
        token,
        searchQuery,
        LIST_TYPE.ACTIVE
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      setAddedListCountDevice(res?.data?.count);

      if (res?.code === STATUS_CODE.SUCCESS) {
        const newDevices = res.data.device_list;

        if (Array.isArray(newDevices)) {
          const formattedDeviceList = newDevices.map((device) => {
            if (device.maintenance_date) {
              const formattedDate = moment(device.maintenance_date).format(
                DATE_FORMATES.DD_MM_YY
              );
              device.maintenance_date = formattedDate;
            }
            return device;
          });

          if (currentPageForDevice === 1) {
            setDevices(formattedDeviceList);
          } else {
            setDevices((prevDevices) => [
              ...prevDevices,
              ...formattedDeviceList,
            ]);
          }
          setIsLoadMore(res?.data?.loadMore);
        }
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error(t("ERROR_FETCHING_DEVICE_LIST"), error);
    }
  };

  useEffect(() => {
    getDeviceList(currentPageForDevice, searchQuery);
  }, [showDevice]);

  //for isMulti
  useEffect(() => {
    const options = devices
      .filter((device) => device.is_active === 1)
      .map((device) => ({
        id: device.id,
        // name: `${device.device_title} (${device.device_name})`,
        name: device.device_title,
      }));
    setDeviceOptions(options);
  }, [devices]);

  const handleShowDevice = () => {
    setDeviceName(deviceNameField);
    setSelectedDevice(sendDevice);
    setShowDevice(true);
  };
  const handleCloseDevice = () => {
    // setDeviceName(deviceNameField);
    // setSelectedDevice(sendDevice);
    // setDeviceNameField(deviceName);
    // setSendDevice(selectedDevice);

    setSearchQuery("");
    setShowDevice(false);
    setCurrentPageForDevice(1);
  };

  const debouncedSearchDevice = useDebounce((term) => {
    getDeviceList(1, term, 1);
  }, 500);

  const handleSubmitDevice = () => {
    setDeviceNameField(deviceName);
    setSendDevice(selectedDevice);

    handleCloseDevice();
    setSearchQuery("");
    setCurrentPageForDevice(1);
  };

  const handleDeviceChange = (e, device) => {
    const { value, checked } = e.target;
    let updatedDevices;
    let updatedDevicesName;
    if (checked) {
      updatedDevices = [...selectedDevice, device.id];
      updatedDevicesName = [...deviceName, device.name];
    } else {
      updatedDevices = selectedDevice.filter((id) => id !== device.id);

      updatedDevicesName = deviceName.filter((name) => name !== device.name);
    }

    setSelectedDevice(updatedDevices);
    setDeviceName(updatedDevicesName);
    // updateDeviceNames(updatedDevices);
  };

  const updateDeviceNames = (selectedDeviceIds) => {
    const selectedNames = deviceOptions
      .filter((device) => selectedDeviceIds.includes(device.id))
      .map((device) => device.name)
      .join(", ");
    setDeviceName(selectedNames);
  };

  // const getTagList = (currentPageForTag, searchQuery) => {
  //   IndependicareApi.getTagList(currentPageForTag, token, searchQuery).then(
  //     (res) => {
  //       const responseCode = STATUS_MSG[res?.data?.code];
  //       if (res?.code === STATUS_CODE.SUCCESS) {
  //         const newTags = res?.data?.tag_list;

  //         if (currentPageForTag === 1) {
  //           // If it's the first page, replace the list
  //           setTagList(newTags);
  //         } else {
  //           // Otherwise, append new data to the existing list
  //           setTagList((prevTags) => [...prevTags, ...newTags]);
  //         }

  //         setAddedListCountTag(res?.data?.count);
  //         setIsLoadMore(res?.data?.loadMore); // Update the load more flag based on the API response
  //       } else if (
  //         res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
  //         res?.data?.code === STATUS_CODE.INACTIVE_USER
  //       ) {
  //         dispatch(clearUserState());
  //         Toster(t("SESSION_EXPIRED"), "error");
  //         navigate("/signin");
  //       } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
  //         Toster(t(responseCode), "error");
  //       } else {
  //         Toster(t(responseCode), "error");
  //       }
  //     }
  //   );
  // };

  // useEffect(() => {
  //   getTagList(currentPageForTag, searchQuery);
  // }, [showTagModel]);

  // useEffect(() => {
  //   const options = tagList
  //     .filter((tag) => tag.is_active === 1)
  //     .map((tag) => ({
  //       id: tag.id,
  //       // label: tag.location_type,
  //       name: tag.tag_uniq_id,
  //     }));
  //   setTagOptions(options);
  // }, [tagList]);

  // const handleShowTag = () => setShowTagModel(true);
  // const handleCloseTag = () => setShowTagModel(false);

  // const debouncedSearchTag = useDebounce((term) => {
  //   getTagList(1, term, 1);
  // }, 500);

  // const handleSubmitTag = () => {
  //   // if (selectedTag.length === 0) {
  //   //   Toster(t("PLEASE_SELECT_DEVICE"), "error");
  //   //   return;
  //   // }
  //   setTagNameField(tagName);
  //   setSendSelectedTag(selectedTag);

  //   handleCloseTag();
  //   setSearchQuery("");
  // };

  // const handleTagChange = (e, tag) => {
  //   const { value, checked } = e.target;
  //   let updatedTags;
  //   if (checked) {
  //     updatedTags = [...selectedTag, tag.id];
  //   } else {
  //     updatedTags = selectedTag.filter((id) => id !== tag.id);
  //   }

  //   setSelectedTag(updatedTags);
  //   updateTagNames(updatedTags);
  // };

  // const updateTagNames = (selectedTagIds) => {
  //   const selectedNames = tagOptions
  //     .filter((device) => selectedTagIds.includes(device.id))
  //     .map((tag) => tag.name)
  //     .join(", ");
  //   setTagName(selectedNames);
  // };

  const getPersonList = (currentPageForPerson, searchQuery) => {
    IndependicareApi.getPersonList(
      token,
      currentPageForPerson,
      searchQuery,
      LIST_TYPE.ACTIVE
    ).then((res) => {
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const newPersons = res?.data?.person_list;
        if (currentPageForPerson === 1) {
          setGetPerson(newPersons);
        } else {
          setGetPerson((prevPersons) => [...prevPersons, ...newPersons]);
        }
        setAddedCaregiverListCountPerson(res?.data?.count);
        setIsLoadMore(res?.data?.loadMore);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(res?.data?.message, "error");
      } else {
        Toster(t(responseCode), "error");
      }
    });
  };

  useEffect(() => {
    getPersonList(currentPageForPerson, searchQuery);
  }, [showPerson]);

  useEffect(() => {
    const options = getPerson.map((person) => ({
      value: person.id,
      label: person.person_name,
    }));
    setPersonOption(options);
  }, [getPerson]);

  const handleSubmitPerson = () => {
    setPerson(selectedPerson);
    setPersonName(selectedLabelNames.join(","));
    handleClosePerson();
    setSearchQuery("");
    setCurrentPageForPerson(1);
    // }
  };

  const handleChange = (event, label) => {
    const id = event.target.value;
    const checked = event.target.checked;
    setSelectedPerson((prev) =>
      checked ? [...prev, id] : prev.filter((item) => item !== id)
    );
    if (checked) {
      setSelectedLabelNames([...selectedLabelNames, label]);
    } else {
      const temparr = selectedLabelNames?.filter((item) => item != label);
      setSelectedLabelNames([...temparr]);
    }
  };

  const selectedLabels = personOption
    .filter((option) => selectedPerson.includes(option.value))
    .map((option) => option.label)
    .join(", ");

  useEffect(() => {
    if (siteItem) {
      setSiteIdToUpdate(siteItem.id);
      setSiteUID(siteItem.site_uid);
      setValue("notes", siteItem.notes);
      setValue("siteTitle", siteItem.site_title);
      setValue("reader", siteItem.reader_location);
      setValue("independiCare", siteItem.representative);

      if (siteItem && siteItem.start_date) {
        const dateString = siteItem.start_date;
        const parts = dateString.split("/");

        if (parts.length === 3) {
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1;
          const year = parseInt(parts[2], 10);

          const dateObject = new Date(year, month, day);

          setSelectDate(dateObject);
          setValue("date", dateObject);
        } else {
          console.error(t("INVALID_DATE_FORMAT"), dateString);
        }
      } else {
        console.error(t("START_DATE_IS_UNDEFINED_OR_INVALID"), siteItem);
      }

      if (siteItem.device_id) {
        const deviceIdArray = Array.isArray(siteItem.device_id)
          ? siteItem.device_id
          : siteItem.device_id.toString().split(",");
        setSelectedDevice(deviceIdArray);
        setSendDevice(deviceIdArray);

        // const selectedDevices = deviceIdArray
        //   .map((id) => {
        //     const matchedDevice = deviceOptions.find(
        //       (device) => device.id === id
        //     );
        //     return matchedDevice
        //       ? { value: matchedDevice.id, label: matchedDevice.name }
        //       : null;
        //   })
        //   .filter(Boolean);

        // setDeviceName(selectedDevices.map((device) => device.label));
        // setDeviceNameField(selectedDevices.map((device) => device.label));
        // setValue("selectedDevice", selectedDevices);
        // debugger;
        const deviceTitle = siteItem?.device_title
          ?.split(",")
          .map((name) => name.trim());
        setDeviceName([...deviceTitle]);
        setDeviceNameField([...deviceTitle]);

        setValue("sendDevice", deviceIdArray);
      } else {
        setSelectedDevice([]);
        setDeviceName([]);
      }

      // if (siteItem.tag_id) {
      //   const tagIdArray = Array.isArray(siteItem.tag_id)
      //     ? siteItem.tag_id
      //     : siteItem.tag_id.toString().split(",");

      //   const selectedTag = tagIdArray
      //     .map((id) => {
      //       const matchedTag = tagOptions.find((tag) => tag.id === id);
      //       return matchedTag
      //         ? { value: matchedTag.id, label: matchedTag.name }
      //         : null;
      //     })
      //     .filter(Boolean);

      //   setSelectedTag(selectedTag);
      //   setValue("selectedTag", selectedTag);
      // }
      // const [tagNameField, setTagNameField] = useState("");
      // const [sendSelectedTag, setSendSelectedTag] = useState([]);

      // if (siteItem.tag_id) {
      //   const tagIdArray = Array.isArray(siteItem.tag_id)
      //     ? siteItem.tag_id
      //     : siteItem.tag_id.toString().split(",");

      //   setSelectedTag(tagIdArray);
      //   setSendSelectedTag(tagIdArray);

      //   const selectedTags = tagIdArray
      //     .map((id) => {
      //       const matchedTags = tagOptions.find((tags) => tags.id === id);
      //       return matchedTags
      //         ? { value: matchedTags.id, label: matchedTags.name }
      //         : null;
      //     })
      //     .filter(Boolean);

      //   setTagName(selectedTags.map((device) => device.label));
      //   setTagNameField(selectedTags.map((device) => device.label));
      //   setValue("selectedTag", selectedTags);
      // } else {
      //   setSelectedTag([]);
      //   setTagName([]);
      // }

      if (siteItem.person_id) {
        const personIds = Array.isArray(siteItem.person_id)
          ? siteItem.person_id
          : siteItem.person_id.toString().split(",");

        setSelectedPerson(personIds);
        setPerson(personIds);
        const selectedNames = personOption
          .filter((option) => personIds.includes(option.value))
          .map((option) => option.label);

        // setPerson(selectedNames);
        const arr = siteItem?.person_names
          ?.split(",")
          .map((name) => name.trim());
        setPersonName([...arr]);
        setSelectedLabelNames([...arr]);

        // selectedLabelNames;
        setValue("person", personIds);
      } else {
        setSelectedPerson([]);
        setPerson([]);
      }
    }
    // else {
    //   resetForm();
    // }
  }, [siteItem]);

  const debouncedSearchPerson = useDebounce((term) => {
    getPersonList(1, term, 1);
  }, 500);

  const onSubmit = (data) => {
    // const selectedDeviceValues = selectedDevice
    //   ? selectedDevice.map((option) => option.value)
    //   : [];

    // const selectedTagValues = selectedTag
    //   ? selectedTag.map((option) => option.value)
    //   : [];

    const formData = new FormData();

    const formattedDate = data.date
      ? format(new Date(data.date), "yyyy-MM-dd")
      : "";

    formData.append("site_title", data.siteTitle);
    formData.append("device_id", sendDevice.join(","));
    // formData.append("tag_id", sendSelectedTag.join(","));
    formData.append("person_id", person);
    formData.append("start_date", formattedDate);
    formData.append("representative", data.independiCare);
    formData.append("reader_location", data.reader);
    formData.append("notes", data.notes);

    if (!siteIdToUpdate) {
      setIsLoading(true);
      IndependicareApi.addSite(formData, token).then((res) => {
        setIsLoading(false);
        const responseCode = STATUS_MSG[res?.data?.code];
        if (res?.code === STATUS_CODE.SUCCESS) {
          Toster(t("SITE_ADDED_SUCCESFULLY"), "success");
          handleCloseModal();
          siteList();
          setSiteIdToUpdate("");

          resetForm();
        } else if (
          res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          res?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (
          res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR ||
          res?.code === STATUS_CODE.THE_TIME_MUST_BE_CURRENT_OR_FUTURE_TIME
        ) {
          Toster(res?.data?.message, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      });
    } else {
      setIsLoading(true);
      IndependicareApi.updateSite(formData, siteIdToUpdate, token).then(
        (res) => {
          setIsLoading(false);
          const responseCode = STATUS_MSG[res?.data?.code];
          if (res?.code === STATUS_CODE.SUCCESS) {
            Toster(t("SITE_UPDATED_SUCCESFULLY"), "success");
            handleCloseModal();
            siteList();
            setSiteIdToUpdate("");
            resetForm();
          } else if (
            res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            res?.data?.code === STATUS_CODE.INACTIVE_USER
          ) {
            dispatch(clearUserState());
            Toster(t("SESSION_EXPIRED"), "error");
            navigate("/signin");
          } else if (
            res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR ||
            res?.code === STATUS_CODE.THE_TIME_MUST_BE_CURRENT_OR_FUTURE_TIME
          ) {
            Toster(res?.data?.message, "error");
          } else {
            Toster(t(responseCode), "error");
          }
        }
      );
    }
  };

  const resetForm = () => {
    reset({
      siteTitle: "",
      notes: "",
      reader: "",
      independiCare: "",

      date: new Date(),
      selectedDevice: [],
      selectedTag: [],
      person: [],
    });

    setSelectedDevice([]);
    setSelectedPerson([]);
    setPerson([]);
    setPersonName([]);
    setSelectedLabelNames([]);
    setSelectDate(new Date());
    // setSelectedTag([]);
    setSiteIdToUpdate("");
    setSelectedDevice([]);
    setDeviceName([]);
    setDeviceNameField("");
    setSendDevice([]);
    // setTagName([]);
    // setTagNameField("");
    // setSendSelectedTag([]);

    if (multiSelectRef.current) {
      if (typeof multiSelectRef.current.reset === "function") {
        multiSelectRef.current.reset();
      } else {
        multiSelectRef.current.value = "";
      }
    }
  };

  // const handleSelectChangePermission = (selectedOptions) => {
  //   if (selectedOptions) {
  //     setSelectedDevice(selectedOptions);
  //   } else {
  //     setSelectedDevice([]);
  //   }
  // };

  // const handleSelectChangeTag = (selectedOptions) => {
  //   if (selectedOptions) {
  //     setSelectedTag(selectedOptions);
  //   } else {
  //     setSelectedTag([]);
  //   }
  // };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          handleCloseModal();
          resetForm();
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title className={`setModelTitleProperty ${styles.modelTitle}`}>
            {siteIdToUpdate ? t("UPDATE_SITE") : t("ADD_SITE")}
          </Modal.Title>
          {/* <CancelIcon
            className="closeIconProperty"
            onClick={() => {
              resetForm();
              handleCloseModal();
            }}
          /> */}
          {/* <Tooltip title={t("CLOSE")} placement="top">
           <CancelIcon
              className="closeIconProperty"
              onClick={() => {
                resetForm();
                handleCloseModal();
              }}
            />
          </Tooltip> */}
          <OverlayTrigger
            placement="top" // You can adjust the position (top, bottom, left, right)
            overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
          >
            <CancelIcon
              className="closeIconProperty"
              onClick={() => {
                resetForm();
                handleCloseModal();
              }}
            />
          </OverlayTrigger>
          {/*<CancelIcon className="closeIconProperty" onClick={closeModel} />*/}
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {isLoading && <Loader />}
            {siteIdToUpdate && (
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  paddingTop: "2px",
                  paddingBottom: "20px",
                }}
              >
                {t("SITE_ID")}: {siteUID}
              </p>
            )}
            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_SITE_TITLE")}
              RegisterName="siteTitle"
              register={register}
              formState={formState}
              label={t("SITE_TITLE")}
              className={styles.addSiteProperty}
              onChange={(e) => {
                setValue("siteTitle", e.target.value.replace(/\s/g, " "), {
                  shouldValidate: true,
                });
              }}
            />
            {/* <CustomMultiSelectFields
              PlaceHolder={t("PLEASE_SELECT_A_FIXED_DEVICE")}
              RegisterName="selectedDevice"
              register={register}
              options={deviceOptions}
              formState={formState}
              label={t("DEVICE")}
              value={selectedDevice}
              onChangeHandler={handleSelectChangePermission}
            /> */}
            <div className={`border-none ${styles.deviceField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.deviceLabel}>
                  {t("DEVICE")}
                </Form.Label>
                <Form.Control
                  type="text"
                  value={deviceNameField} // Display all selected device names
                  placeholder={t("PLEASE_SELECT_A_DEVICE")}
                  onClick={handleShowDevice}
                  readOnly
                  className={`${styles.addDeviceProperty} ${styles.inputStyle}`}
                  isInvalid={showValidation}
                />
              </Form.Group>

              {/* Modal for selecting devices */}
              <Modal show={showDevice} onHide={handleCloseDevice}>
                <Modal.Header className="d-flex justify-content-between">
                  <Modal.Title className="setModelTitleProperty">
                    {t("SELECT_OPTIONS")}
                  </Modal.Title>
                  <CancelIcon
                    className="closeIconProperty"
                    onClick={handleCloseDevice}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center">
                        <CustomSearchField
                          setSearchQuery={(val) => {
                            setSearchQuery(val);
                            debouncedSearchDevice(val);
                          }}
                        />
                      </div>
                      <div
                        className={styles.deviceListContainer}
                        ref={listInnerRef}
                        onScroll={debounce(handleScrollDevice, 100)}
                      >
                        {deviceOptions && deviceOptions.length > 0 ? (
                          deviceOptions.map((option) => (
                            <div
                              key={option.id}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Form.Label htmlFor={`device-${option.id}`}>
                                {option.name}
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                id={`device-${option.id}`}
                                value={option.id}
                                name="device" // Name remains the same for grouping checkboxes
                                checked={selectedDevice.includes(option.id)}
                                onChange={(e) => handleDeviceChange(e, option)}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center p-2">
                            <p>{t("NO_RECORD_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={handleSubmitDevice}
                  >
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {/* for the tag */}
            {/* <CustomMultiSelectFields
              PlaceHolder={t("PLEASE_SELECT_A_FIXED_TAG")}
              RegisterName="selectedTag"
              register={register}
              options={tagOptions}
              formState={formState}
              label={t("TAG")}
              value={selectedTag}
              onChangeHandler={handleSelectChangeTag}
            /> */}
            {/* <div className={`border-none ${styles.tagField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.tagLabel}>{t("TAG")}</Form.Label>
                <Form.Control
                  type="text"
                  value={tagNameField}
                  placeholder={t("PLEASE_SELECT_A_FIXED_TAG")}
                  onClick={handleShowTag}
                  readOnly
                  className={`${styles.addDeviceProperty} ${styles.inputStyle}`}
                  isInvalid={showValidation}
                />
              </Form.Group>
              <Modal show={showTagModel} onHide={handleCloseTag}>
                <Modal.Header className="d-flex justify-content-between">
                  <Modal.Title className="setModelTitleProperty">
                    {t("SELECT_OPTIONS")}
                  </Modal.Title>
                  <CancelIcon
                    className="closeIconProperty"
                    onClick={handleCloseTag}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center">
                        <CustomSearchField
                          setSearchQuery={(val) => {
                            setSearchQuery(val);
                            debouncedSearchTag(val);
                          }}
                        />
                      </div>
                      <div
                        className={styles.tagListContainer}
                        ref={listInnerRef}
                        onScroll={debounce(handleScrollTags, 100)}
                      >
                        {tagOptions && tagOptions.length > 0 ? (
                          tagOptions.map((option) => (
                            <div
                              key={option.id}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Form.Label htmlFor={`tag-${option.id}`}>
                                {option.name}
                              </Form.Label>
                              <Form.Check
                                type="checkbox"
                                id={`tag-${option.id}`}
                                value={option.id}
                                name="tag"
                                checked={selectedTag.includes(option.id)}
                                onChange={(e) => handleTagChange(e, option)}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center p-2">
                            <p>{t("NO_RECORD_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="w-100"
                    onClick={handleSubmitTag}
                  >
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div> */}
            <div className={`border-none ${styles.personField}`}>
              <Form.Group className="mb-3">
                <Form.Label className={styles.personLabel}>
                  {t("PERSON")}
                </Form.Label>
                <Form.Control
                  type="text"
                  // value={person}
                  value={personName}
                  placeholder={t("SELECT_PERSON")}
                  onClick={handleShowPerson}
                  readOnly
                  className={`${styles.addDeviceProperty} ${styles.inputStyle}`}
                  isInvalid={showValidation}
                />
                {/* <Form.Control.Feedback type="invalid">
                  {t("PLEASE_SELECT_AT_LEAST_ONE_PERSON")}
                </Form.Control.Feedback> */}
              </Form.Group>
              <Modal show={showPerson} onHide={handleClosePerson}>
                <Modal.Header className="d-flex justify-content-between">
                  <Modal.Title className="setModelTitleProperty">
                    {t("SELECT_OPTIONS")}
                  </Modal.Title>
                  <CancelIcon
                    className="closeIconProperty"
                    onClick={handleClosePerson}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group>
                    <div className="d-flex flex-column">
                      <div className="d-flex justify-content-center">
                        <CustomSearchField
                          setSearchQuery={(val) => {
                            setSearchQuery(val);
                            debouncedSearchPerson(val);
                          }}
                        />
                      </div>
                      <div
                        className={styles.personListContainer}
                        ref={listInnerRef}
                        onScroll={debounce(handleScroll, 100)}
                      >
                        {personOption && personOption.length > 0 ? (
                          personOption.map((option) => (
                            <div
                              key={option.id}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <Form.Label htmlFor={`person-${option.value}`}>
                                {option.label}
                              </Form.Label>
                              <Form.Check
                                key={option.value}
                                type="checkbox"
                                id={`person-${option.value}`}
                                value={option.value}
                                checked={selectedPerson.includes(option.value)}
                                onChange={(e) => handleChange(e, option.label)}
                              />
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center p-2">
                            <p>{t("NO_RECORD_FOUND")}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    className="w-100"
                    // onClick={handleClose}
                    onClick={handleSubmitPerson}
                  >
                    {t("Submit")}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <CustomDatePicker
              control={control}
              RegisterName="date"
              register={register}
              formState={formState}
              label={t("START_DATE")}
              PlaceHolder={t("SELECT_DATE")}
              className={`${styles.addSiteProperty} ${styles.textSize} ${styles.textBottom}`}
              defaultValue={selectDate}
              rules={{
                required: t("DATE_IS_REQUIRED"),
                validate: (value) => (value ? true : t("INVALID_DATE")),
              }}
            />
            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_INDEPENDICARE_REPRESENTATIVE")}
              RegisterName="independiCare"
              register={register}
              formState={formState}
              label={t("INDEPENDICARE_REPRESENTATIVE")}
              className={styles.addSiteProperty}
              onChange={(e) => {
                setValue("independiCare", e.target.value.replace(/\s/g, " "), {
                  shouldValidate: true,
                });
              }}
            />
            <CustomInputFieldsModelForm
              type="text"
              PlaceHolder={t("ENTER_READER_LOCATION")}
              RegisterName="reader"
              register={register}
              formState={formState}
              label={t("READER_LOCATION")}
              className={styles.addSiteProperty}
              onChange={(e) => {
                setValue("reader", e.target.value.replace(/\s/g, " "), {
                  shouldValidate: true,
                });
              }}
            />
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className={styles.textAreaLabel}>
                {t("NOTES")}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                style={{
                  height: "75px",
                  fontSize: "15px",
                  fontWeight: "400",
                  borderColor: errors.notes ? "red" : "#E3E3E3",
                }}
                placeholder={t("ENTER_NOTES")}
                className={`${styles.addSiteProperty}`}
                // {...register("notes")}
                {...register("notes", {
                  // required: t("NOTES_REQUIRED"),
                  minLength: {
                    value: 2,
                    message: t("NOTES_TEXT_MINIMUM_2_CH"),
                  },
                  maxLength: {
                    value: 300,
                    message: t("NOTES_TEXT_NOT_MORE_THAN_300_CH"),
                  },
                  // validate: {
                  //   noWhitespace: (value) =>
                  //     value.trim() !== "" ||
                  //     t("NOTES_SHOULD_NOT_BE_WHITESPACES_ONLY"),
                  // },
                  validate: {
                    // Allow empty values or values with non-whitespace characters
                    noWhitespace: (value) =>
                      value.trim() === "" ||
                      value.trim() !== "" ||
                      t("NOTES_SHOULD_NOT_BE_WHITESPACES_ONLY"),
                  },
                })}
                isInvalid={!!errors.notes}
              />
              <Form.Control.Feedback type="invalid">
                {errors.notes?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="AddSiteSubmitButton" type="submit">
              {siteIdToUpdate ? t("UPDATE_SITE") : t("ADD_SITE")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}
export default Addsite;
