import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../Assests/Images/edit.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import { Row, Col, Container, Table, Image, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import AddDevice from "./AddDevice";
import IndependicareApi from "../../Helpers/Api";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../LoaderComponent/LoaderComponent";
import Toster from "../../Toster/Toster";
import style from "./DeviceList.module.css";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserState } from "../../redux/slices/userSlice";
import { DATE_FORMATES } from "../../Utils/Constant";
import useDebounce from "../../Helpers/Debounce";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import styles from "./DeviceList.module.css";
import Confirm from "../../Common/Confirm";

function DeviceList() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [eventIdToDelete, setEventIdToDelete] = useState(null);
  const [devices, setDevices] = useState([]);
  const [deviceMaintenanceDate, setDeviceMaintenanceDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [deviceItem, setDeviceItem] = useState("");
  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.userToken);
  const usertype = useSelector((state) => state.user.userType);
  const [currentPage, setCurrentPage] = useState(1);
  const [addedCaregiverListCount, setAddedCaregiverListCount] = useState(0);
  const pageLimit = 10;

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getDeviceList(pageNumber, searchQuery);
  };

  // const debouncedSearch = useDebounce((term) => {
  //   getDeviceList(currentPage, term, 1);
  // }, 500);
  const debouncedSearch = useDebounce((term) => {
    getDeviceList(1, term, 1);
    if (term == "") {
      setCurrentPage(1);
    }
  }, 500);

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const handleShowModal = (item = null) => {
    setShowModal(true);
  };

  const getDeviceList = async (page, searchTerm) => {
    try {
      setIsLoading(true);
      const userCurrentPage = page;
      const search = searchTerm;
      const res = await IndependicareApi.getDeviceList(
        userCurrentPage,
        token,
        search
      );
      setIsLoading(false);
      setSearchQuery(searchTerm);
      setCurrentPage(page);

      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        const deviceList = res.data.device_list;
        setAddedCaregiverListCount(res?.data?.count);
        if (Array.isArray(deviceList)) {
          const formattedDeviceList = deviceList.map((device) => {
            if (device.maintenance_date) {
              const formattedDate = moment(device.maintenance_date).format(
                DATE_FORMATES.DD_MM_YY
              );
              device.maintenance_date = formattedDate;
            }
            return device;
          });

          setDevices(formattedDeviceList);
        }
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching device list:", error);
    }
  };

  useEffect(() => {
    getDeviceList(1, searchQuery);
  }, []);

  const deleteData = async (id) => {
    setEventIdToDelete(id);
    setTitleText(t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_DEVICE"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const deleteContent = async () => {
    setIsLoading(true);

    try {
      const response = await IndependicareApi.deleteDevice(
        eventIdToDelete,
        token
      );
      const responseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        getDeviceList(1, searchQuery);
        Toster(t("DEVICE_DELETED_SUCCESSFULLY"), "success");
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      console.error("Error deleting device:", error);
    } finally {
      setIsLoading(false);
      setIsShowConfirm(false);
    }
  };

  const updateData = (item) => {
    setSelectedItem(item);
    handleShowModal();
  };

  const handleLocationStatusUpdate = (deviceItem) => {
    setIsLoading(true);
    IndependicareApi.deviceStatus(deviceItem.id, token).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("DEVICE_STATUS_UPDATED_SUCCESSFULLY"), "success");
          getDeviceList(1, searchQuery);
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const activeInactiveFunction = (item) => {
    setDeviceItem(item);
    if (item.is_active === 1) {
      setTitleText(t("DEVICE_INACTIVE"));
    } else {
      setTitleText(t("DEVICE_ACTIVE"));
    }
    setIsDeleting(false);
    setIsShowConfirm(true);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    isDeleting ? deleteContent() : handleLocationStatusUpdate(deviceItem);
  };

  return (
    <div className="d-flex flex-column justify-content-between h-100 w-100">
      {isLoading && <Loader />}
      <div style={{ height: "95%" }}>
        <div className="">
          <Container>
            <Row className="align-items-center">
              <Col
                className="setSearchInputField"
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                <CustomSearchField
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearch(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={handleShowModal}
                  >
                    {t("ADD_DEVICE")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ marginBottom: 20 }}>
          <Container>
            <div className={styles?.eventTable}>
              <div className="setTableFromBottom">
                {devices.length > 0 ? (
                  // <Table
                  //   className="customTabled"
                  //   striped
                  //   hover
                  //   responsive
                  //   // style={{ height: "300px" }}
                  // >
                  //   <thead className="tableHeadProperty">
                  <Table className={styles.customTable} striped>
                    <thead className={styles.tableHeadProperty}>
                      <tr>
                        <th>{t("DEVICE_TITLE")}</th>
                        {/* <th>{t("DEVICE_ID")}</th> */}
                        <th>{t("DEVICE_SERIAL_NUMBER")}</th>
                        <th>{t("DEVICE_MAINTENANCE_DATE")}</th>
                        {/* <th>{t("DEVICE_NAME")}</th> */}
                        <th>{t("DEVICE_TYPE")}</th>
                        {/* <th>{t("DEVICE_TYPE")}</th> */}
                        <th>{t("PERSON")}</th>
                        <th>{t("STATUS")}</th>
                        <th>{t("ACTION")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {devices.map((item, index) => (
                        <tr key={index}>
                          {/* <td style={tdAlignClass} className="tdtext">
                            {item.device_title}
                          </td> */}
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            <div className="tdContentWrapper">
                              {item.device_title}
                            </div>
                          </td>
                          {/* <td style={tdAlignClass} className="tdtext">
                            {item.device_id}
                          </td> */}
                          {/* <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            <div className="tdContentWrapper">
                              {item.device_id}
                            </div>
                          </td> */}
                          {/* <td style={tdAlignClass} className="tdtext">
                            {item.device_serial_number}
                          </td> */}
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            {/* {item.notes} */}
                            <div className="tdContentWrapper">
                              {item.device_serial_number}
                            </div>
                          </td>
                          <td style={tdAlignClass} className="tdtext">
                            {moment(
                              item.maintenance_date,
                              DATE_FORMATES.DD_MM_YY
                            ).format(DATE_FORMATES.MM_DD_YY)}
                          </td>
                          <td style={tdAlignClass} className="tdtext">
                            {item.device_name}
                          </td>
                          {/* <td style={tdAlignClass} className="tdtext">
                          {item.device_type}
                        </td> */}
                          {/* <td style={tdAlignClass} className="tdtext">
                            {item.person_names}
                          </td> */}
                          <td
                            style={{
                              ...tdAlignClass,
                            }}
                            className="tdtextNotes tdtext"
                          >
                            <div className="tdContentWrapper">
                              {item.person_names}
                            </div>
                          </td>
                          <td style={tdAlignClass} className="tdtext">
                            {item.is_active === 1 ? t("ACTIVE") : t("INACTIVE")}
                          </td>
                          <td>
                            <Dropdown className="setParentDropdown">
                              <Dropdown.Toggle
                                variant=""
                                id="dropdown-basic"
                                className="custom-dropdown-toggle p-0 border-0"
                              >
                                <MoreHorizIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => {
                                    updateData(item);
                                  }}
                                >
                                  <Dropdown.Item>{t("EDIT")}</Dropdown.Item>
                                  <Image src={edit} className="dropdownIcons" />
                                </div>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => {
                                    deleteData(item.id);
                                  }}
                                >
                                  <Dropdown.Item>{t("DELETE")}</Dropdown.Item>
                                  <DeleteOutlineIcon className="iconColor" />
                                </div>
                                <div
                                  className="d-flex justify-content-around dropdownTextProperty"
                                  onClick={() => {
                                    activeInactiveFunction(item);
                                  }}
                                >
                                  <Dropdown.Item>{`${
                                    item.is_active == 1
                                      ? t("INACTIVE")
                                      : t("ACTIVE")
                                  }`}</Dropdown.Item>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className={style.noRecord}>
                    <p>{t("NO_RECORD_FOUND")}</p>
                  </div>
                )}
              </div>
            </div>
          </Container>
          <AddDevice
            showModal={showModal}
            handleCloseModal={handleCloseModal}
            selectedItem={selectedItem}
            getDeviceList={() => {
              getDeviceList(1, searchQuery);
            }}
            pageNo={currentPage}
          />
        </div>
        <Pagination
          count={addedCaregiverListCount}
          pageLimit={pageLimit}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </div>

      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />
    </div>
  );
}
export default DeviceList;
