import React, { useEffect, useState } from "react";
import styles from "../StakeholderDetail/StakeholderDetail.module.css";
import Table from "react-bootstrap/Table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import Loader from "../LoaderComponent/LoaderComponent";

function Site({ id, siteList, count }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.userToken);

  const [list, setList] = useState(siteList);

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const tdAlignClass = { flex: 1, alignContent: "center" };

  const pageLimit = 10;
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payLoads = {
      id: id,
      page_no: pageNumber,
      pagination_type: "site",
    };
    getList(payLoads);
  };

  const getList = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getStakeholderDetails(token, payLoads?.id, payLoads).then(
      (response) => {
        const responseCode = STATUS_MSG[response?.data?.code];
        setIsLoading(false);
        if (response?.code === STATUS_CODE.SUCCESS) {
          setList(response?.data?.siteList);
        } else if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(t(responseCode), "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    );
  };
  return (
    <div className="d-flex flex-column justify-content-between h-111 w-100">
      {isLoading && <Loader />}
      <div>
        {list.length > 0 ? (
          <Table striped responsive>
            <thead className={styles.thead}>
              <tr>
                <th>{t("SITE_ID")}</th>
                <th>{t("SITE_TITLE")}</th>
                <th>{t("START_DATE")}</th>
                <th>{t("PERSON")}</th>
                <th>{t("INDEPENDICARE_REPRESENTATIVE")}</th>
                <th>{t("RADER_LOCATION")}</th>
                <th className={styles.notesText}>{t("NOTES")}</th>
              </tr>
            </thead>
            <tbody>
              {list?.map((item, index) => {
                return (
                  <tr key={index}>
                    {/* <td>{item.site_title}</td> */}
                    <td>{item.site_uid}</td>
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">{item.site_title}</div>
                    </td>
                    <td>{moment(item.start_date)?.format("DD/MM/YY")}</td>
                    {/* <td>{item.person_names}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">
                        {item.person_names}
                      </div>
                    </td>
                    {/* <td>{item.representative}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">
                        {item.representative.length === 0
                          ? t("NA")
                          : item.representative}
                      </div>
                    </td>
                    {/* <td>{item.reader_location}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">
                        {item.reader_location.length === 0
                          ? t("NA")
                          : item.reader_location}
                      </div>
                    </td>
                    {/* <td className={styles.center}>{item.notes}</td> */}
                    <td
                      style={{
                        ...tdAlignClass,
                      }}
                      // className=""
                      className="tdtextNotes tdtext"
                    >
                      <div className="tdContentWrapper">
                        {item.notes.length === 0 ? t("NA") : item.notes}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className={styles.noRecord}>
            <p>{t("NO_RECORD_FOUND")}</p>
          </div>
        )}
      </div>
      <Pagination
        count={count}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
    </div>
  );
}
export default Site;
