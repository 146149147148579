import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../../Helpers/Api";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomSearchField from "../../../Components/CustomComponent/CustomSearchField/CustomSearchField";
import styles from "../../../Components/Settings/Relation/Relation.module.css";
import edit from "../../../Assests/Images/edit.png";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import { STATUS_MSG } from "../../../Utils/StatusMessage";
import Toster from "../../../Toster/Toster";
import { clearUserState } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../../LoaderComponent/LoaderComponent";
import { ACTIVE_TYPE } from "../../../Utils/Constant";
import RelationModal from "./RelationModal";
import Confirm from "../../../Common/Confirm";

function Relation() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const usertype = useSelector((state) => state.user.userType);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [list, setList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [relationId, setRelationId] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isShowConfirm, setIsShowConfirm] = useState(false);

  const [isShowModal, setIsShowModal] = useState({ show: false, id: "" });

  const handleShowModal = () => {
    setIsShowModal((prev) => ({ ...prev, show: true }));
  };

  const handleEditEvent = (id) => {
    setIsEdit(true);
    setIsShowModal(() => ({ id, show: true }));
  };

  useEffect(() => {
    handelGetData();
  }, []);

  const handelGetData = () => {
    setIsLoading(true);
    IndependicareApi.fetchRelation(token).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        setList(response?.data?.relation);
        setIsLoading(false);
      }
    });
  };

  {
    /*=========Delete Relation ============*/
  }

  const Relationdelete = (relationId) => {
    setIsLoading(true);
    IndependicareApi.deleteRelation(token, relationId).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];

      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("RELATION_DELETED_SUCCESSFULLY"), "success");
        handelGetData();
        setIsShowConfirm(false);
      } else if (
        response?.code === STATUS_CODE.VALIDATIONS_ERROR ||
        response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR
      ) {
        Toster(response?.code?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
      setIsShowConfirm(false);
    });
  };

  /*=========Update Relation status ===============*/
  const Statusrelation = (relationId) => {
    setIsLoading(true);
    IndependicareApi.relationStatus(token, relationId).then((response) => {
      const ResponseCode = STATUS_MSG[response?.data.code];
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/");
        setIsShowConfirm(false);
      } else {
        if (response.code == STATUS_CODE.SUCCESS) {
          Toster(t("RELATION_UPDATE_STATUS_SUCCESS"), "success");
          handelGetData();
          setIsLoading(false);
          setIsShowConfirm(false);
        } else if (
          response.code == STATUS_CODE.VALIDATIONS_ERROR ||
          response.data.code === STATUS_CODE.VALIDATIONS_ERROR
        ) {
          Toster(response.data.message, "error");
          setIsShowConfirm(false);
        } else {
          Toster(t(ResponseCode), "error");
          setIsShowConfirm(false);
        }
      }
    });
  };

  const handleDeleteShow = () => {
    setTitleText(t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_RELATION"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const handleStatusShow = (activeType) => {
    if (activeType === 1) {
      setTitleText(t("ARE_YOU_SURE_YOU_WANT_TO_INACTIVE_THIS_RELATION"));
    } else {
      setTitleText(t("ARE_YOU_SURE_YOU_WANT_TO_ACTIVE_THIS_RELATION"));
    }
    setIsDeleting(false);
    setIsShowConfirm(true);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    isDeleting ? Relationdelete(relationId) : Statusrelation(relationId);
  };

  const filteredList = list.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      {isLoading && <Loader />}
      <div>
        <Container>
          <Row className="align-items-center">
            <Col className="setSearchInputField">
              <CustomSearchField setSearchQuery={setSearchQuery} />
            </Col>
            <Col>
              <div className="modelButtonPropwerty">
                <Button
                  className="modalTextButton"
                  type="button"
                  onClick={handleShowModal}
                >
                  {t("ADD_NEW")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr>
                  <th className={styles.EventalignLeft}>{t("RELATION")}</th>
                  <th className={`${styles.eventAction1} ${styles.alignRight}`}>
                    {t("STATUS")}
                  </th>
                  <th className={`${styles.eventAction} ${styles.alignRight}`}>
                    {t("ACTION")}
                  </th>
                </tr>
              </thead>

              <tbody>
                {filteredList &&
                  filteredList.length > 0 &&
                  filteredList.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className={styles.alignLeft}>{item.name}</td>
                        <td className={styles.alignRight}>
                          {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td className={styles.alignRight}>
                          <Dropdown className={styles.setEventDropdown}>
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="custom-dropdown-menu">
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleEditEvent(item.id);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("EDIT")}</span>
                                  <Image src={edit} className="dropdownIcons" />
                                </Dropdown.Item>
                              </div>
                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  handleDeleteShow();
                                  setRelationId(item.id);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>{t("DELETE")}</span>
                                  <DeleteOutlineIcon className="iconColor" />
                                </Dropdown.Item>
                              </div>

                              <div
                                className="d-flex justify-content-around dropdownTextProperty"
                                onClick={() => {
                                  setRelationId(item.id);
                                  handleStatusShow(item.is_active);
                                }}
                              >
                                <Dropdown.Item>
                                  <span>
                                    {item.is_active == ACTIVE_TYPE.IS_ACTIVE
                                      ? "Inactive"
                                      : "Active"}
                                  </span>
                                </Dropdown.Item>
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Container>
        <div className={styles.noRecord}>
          <p>
            {!isLoading && filteredList.length == 0 ? t("NO_RECORD_FOUND") : ""}
          </p>
        </div>
      </div>

      {/*===============delete-and-status-update-modal ========================*/}
      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />
      {/*===============Add-and-Edit-modal ========================*/}
      <RelationModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        handelGetData={handelGetData}
      />
    </>
  );
}
export default Relation;
