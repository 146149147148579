import React, { useState } from "react";
import style from "./ForgetPassword.module.css";
import { CustomInputFields } from "../CustomComponent/CustomInputFields/CustomInputField";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import { Row, Col } from "react-bootstrap";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useSelector } from "react-redux";
import { forgetPassword } from "../../redux/slices/userSlice";
import Loader from "../LoaderComponent/LoaderComponent";
import { useDispatch } from "react-redux";

function ForgetPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });

  const [isLoading, setIsLoading] = useState(false);
  // const usertype = useSelector((state) => state.user.userType);
  const userType = localStorage.getItem("usertype");
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("user_type", userType);
    // dispatch(forgetPassword(formData))

    setIsLoading(true);
    IndependicareApi.sendCode(formData)
      .then((res) => {
        setIsLoading(false);
        const responseCode = STATUS_MSG[res?.data?.code];
        if (res?.code === STATUS_CODE.SUCCESS) {
          Toster(t("SEND_OTP_TO_THE_REGISTERED_MAIL"), "success");
          navigate("/reset-password", {
            state: { userEmail: data.email },
          });
        } else if (res?.data?.code == STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(res.data.message, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      })
      .catch((error) => {
        Toster(error, "error");
      });
  };
  return (
    <>
      {isLoading && <Loader />}
      <h3 className="headingContent">{t("FORGET_PASSWORD")}</h3>
      <p className={style.paragraphText}>{t("LOREM_IPSUM")}</p>
      <div className="registerFormsField">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <CustomInputFields
            type="text"
            PlaceHolder={t("ENTER_EMAIL_ADDRESS")}
            RegisterName="email"
            register={register}
            formState={formState}
            label={t("EMAIL_ADDRESS")}
          />
          <div className={`${style.buttonContent}`}>
            <Row>
              <Col sm={6} md={6} className="textCol">
                <p className="paragraphLink">
                  <Link className="customLink" to={"/signin"}>
                    <KeyboardArrowLeftIcon className={style.arrowLeftIcon} />
                    {t("BACK_TO_SIGN_IN")}
                  </Link>
                </p>
              </Col>
              <Col
                sm={6}
                md={6}
                className="d-flex justify-content-end setButton font-weight-bold"
              >
                <CustomButton
                  type="submit"
                  value={t("SEND_LINK")}
                  className="buttonProperty"
                />
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
}
export default ForgetPassword;
