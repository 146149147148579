import moment from "moment-timezone";

export function removeExtraPlusSign(str) {
  let newStr = "+" + str;
  return newStr.replace(/\++/g, "+");
}

export const convertUTCToLocal = (utcTimeString, format = "hh:mm:ss A") => {
  // Get the current date
  const currentDate = moment().format("YYYY-MM-DD");

  // Combine current date with the provided UTC time string
  const utcDateTimeString = `${currentDate} ${utcTimeString}`;

  // Parse the combined string as UTC and convert to local timezone
  const localTime = moment
    .utc(utcDateTimeString, "YYYY-MM-DD HH:mm:ss")
    .local();

  // Return the local time in 12-hour format with AM/PM
  return localTime.format(format);
};

export const convertUTCToLocalDatetime = (
  utcDateString,
  utcTimeString,
  format = "MM/DD/YYYY HH:mm:ss"
) => {
  const combinedDateTimeString = `${
    utcDateString.split("T")[0]
  }T${utcTimeString}Z`; // Ensure you add 'Z' for UTC

  const combinedDateTime = moment.utc(combinedDateTimeString).toDate();

  return moment(combinedDateTime).format(format);
};
