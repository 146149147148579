import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import edit from "../../Assests/Images/edit.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CustomSearchField from "../../Components/CustomComponent/CustomSearchField/CustomSearchField";
// import AddPerson from "./AddPerson";
import { useSelector } from "react-redux";
import IndependicareApi from "../../Helpers/Api";
import AddTag from "./AddTag";
import { json, useNavigate } from "react-router-dom";
import Loader from "../LoaderComponent/LoaderComponent";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Toster from "../../Toster/Toster";
import { useDispatch } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import useDebounce from "../../Helpers/Debounce";
import styles from "./AddTag.module.css";
import deleteImage from "../../Assests/Images/delete_image.png";
import { LIST_TYPE } from "../../Utils/Constant";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

function TagList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [list, setList] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const pageNo = 1;
  const token = useSelector((state) => state.user.userToken);

  const [personList, setPersonList] = useState();
  const [personLoadMore, setPersonLoadMore] = useState(false);

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [itemData, setItemData] = useState();

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [addedTagListCount, setAddedTagListCount] = useState(0);
  const pageLimit = 10;
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const payLoads = {
      page_no: pageNumber,
      search: searchQuery,
    };
    getList(payLoads);
  };

  const debouncedSearch = useDebounce((term) => {
    const payLoads = {
      page_no: 1,
      search: term,
    };
    getList(payLoads);
    if (term == "") {
      setCurrentPage(1);
    }
  }, 500);

  const handleCloseModal = () => {
    setItemData(null);
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    getPersons();
    const payLoads = {
      page_no: currentPage,
      search: "",
    };
    getList(payLoads);
  }, []);

  const getList = (payLoads) => {
    setIsLoading(true);
    IndependicareApi.getTagList(token, payLoads).then((response) => {
      setIsLoading(false);
      if (response?.code === STATUS_CODE.SUCCESS) {
        setCurrentPage(payLoads.page_no);
        setSearchQuery(payLoads.search);
        setList(response.data.tag_list);
        setAddedTagListCount(response?.data?.count);
      } else {
        errorHandling(response?.data?.code);
      }
    });
  };

  const getPersons = () => {
    IndependicareApi.getPersonList(token, 1, "", LIST_TYPE.ACTIVE).then(
      (res) => {
        if (res?.code === STATUS_CODE.SUCCESS) {
          setPersonList(res?.data?.person_list);
          setPersonLoadMore(res?.data?.loadMore);
        } else {
          errorHandling(res?.data?.code);
        }
      }
    );
  };

  const callTagDetails = (id) => {
    const payLoads = {
      tagId: id,
    };

    IndependicareApi.getTagDetails(token, id, payLoads).then((res) => {
      if (res?.code === STATUS_CODE.SUCCESS) {
        setItemData(res?.data);
        handleShowModal();
      } else {
        errorHandling(res?.data?.code);
      }
    });
  };

  const deteleCall = (id) => {
    setIsLoading(true);
    IndependicareApi.deleteTag(token, id, {
      tagId: id,
    }).then((response) => {
      setIsLoading(false);
      if (response?.code === STATUS_CODE.SUCCESS) {
        const payLoads = {
          page_no: 1,
          search: "",
        };
        getList(payLoads);
        Toster(t("TAG_DELETED_SUCCESSFULLY"), "success");
      } else {
        errorHandling(response?.data?.code);
      }
    });
  };

  const errorHandling = (errorCode) => {
    const responseCode = STATUS_MSG[errorCode];
    if (
      errorCode === STATUS_CODE.INVALID_TOKEN ||
      errorCode === STATUS_CODE.INACTIVE_USER
    ) {
      dispatch(clearUserState());
      Toster(t("SESSION_EXPIRED"), "error");
      navigate("/signin");
    } else if (errorCode === STATUS_CODE.VALIDATIONS_ERROR) {
      Toster(t(responseCode), "error");
    } else {
      Toster(t(responseCode), "error");
    }
  };

  const deviceOptions = [
    { id: "device1", title: "fixed" },
    { id: "device2", title: "moveable" },
  ];

  const [tagId, setTagId] = useState("");
  const handleShowModalDelete = () => {
    setShowModalDelete(true);
  };
  const handleCloseModalDelete = () => {
    setShowModalDelete(false);
  };

  const deleteContent = () => {
    setShowModalDelete(false);
    deteleCall(tagId);
  };
  const tdAlignClass = { flex: 1, alignContent: "center" };

  return (
    <div className="d-flex flex-column justify-content-between h-105 w-100">
      {isLoading && <Loader />}
      <div>
        <div>
          <Container>
            <Row className="align-items-center">
              <Col
                className="setSearchInputField"
                style={{ paddingBottom: "10px", paddingTop: "10px" }}
              >
                <CustomSearchField
                  value={searchQuery}
                  setSearchQuery={(val) => {
                    setSearchQuery(val);
                    debouncedSearch(val);
                  }}
                />
              </Col>
              <Col>
                <div className="modelButtonPropwerty">
                  <Button
                    className="modalTextButton"
                    type="button"
                    onClick={handleShowModal}
                  >
                    {t("ADD_TAG")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <div className={styles.eventTable}>
              {list?.length > 0 ? (
                <Table className="customTable" striped hover>
                  <thead className="tableHeadProperty">
                    <tr>
                      <th>{t("TAG_ID")}</th>
                      <th>{t("TAG_TITLE")}</th>
                      <th style={{ width: "200px" }}>{t("NOTES")}</th>
                      <th>{t("PERSON_NAME")}</th>
                      <th>{t("LOCATION_TYPE")}</th>
                      <th>{t("SUB_LOCATION_TYPE")}</th>
                      <th>{t("ACTION")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            ...tdAlignClass,
                          }}
                          className="tdtextNotes tdtext"
                        >
                          <div className="tdContentWrapper">
                            {item.tag_uniq_id}
                          </div>
                        </td>
                        <td
                          style={{
                            ...tdAlignClass,
                          }}
                          className="tdtextNotes tdtext"
                        >
                          <div className="tdContentWrapper">
                            {item.tag_title}
                          </div>
                        </td>
                        <td
                          style={{
                            ...tdAlignClass,
                          }}
                          className="tdtextNotes tdtext"
                        >
                          <div className="tdContentWrapper">
                            {item.notes.length === 0 ? t("NA") : item.notes}
                          </div>
                        </td>

                        <td>{item.person_name}</td>
                        <td
                          style={{
                            ...tdAlignClass,
                          }}
                          className="tdtextNotes tdtext"
                        >
                          <div className="tdContentWrapper">
                            {item.location_type}
                          </div>
                        </td>
                        <td
                          style={{
                            ...tdAlignClass,
                          }}
                          className="tdtextNotes tdtext"
                        >
                          <div className="tdContentWrapper">
                            {item.sub_location_type}
                          </div>
                        </td>
                        <td>
                          <Dropdown className="setParentDropdown">
                            <Dropdown.Toggle
                              variant=""
                              id="dropdown-basic"
                              className="custom-dropdown-toggle p-0 border-0"
                            >
                              <MoreHorizIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <div
                                onClick={() => {
                                  callTagDetails(item?.id);
                                }}
                                className="d-flex justify-content-around dropdownTextProperty"
                              >
                                <Dropdown.Item>{t("EDIT")}</Dropdown.Item>
                                <Image src={edit} className="dropdownIcons" />
                              </div>
                              <div
                                onClick={() => {
                                  setTagId(item?.id);
                                  handleShowModalDelete();
                                }}
                                className="d-flex justify-content-around dropdownTextProperty"
                              >
                                <Dropdown.Item>{t("DELETE")}</Dropdown.Item>
                                <DeleteOutlineIcon className="iconColor" />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className={styles.noRecord}>
                  <p>{t("NO_RECORD_FOUND")}</p>
                </div>
              )}
            </div>
          </Container>
        </div>
      </div>
      <Pagination
        count={addedTagListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      <AddTag
        perLoad={personLoadMore}
        perOpt={personList}
        devOpt={deviceOptions}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        getList={() => {
          setCurrentPage(1);
          const payLoads = {
            page_no: 1,
            search: "",
          };
          getList(payLoads);
        }}
        itemData={itemData}
      />

      <Modal
        show={showModalDelete}
        onHide={handleCloseModalDelete}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          {/* <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModalDelete}
          /> */}
          <OverlayTrigger
            placement="top" // You can adjust the position (top, bottom, left, right)
            overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
          >
            <CancelIcon
              className="closeIconProperty"
              onClick={handleCloseModalDelete}
            />
          </OverlayTrigger>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_TAG")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={deleteContent}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default TagList;
