export const menuItems = [
  { id: 0, name: "Sites", link: "/site" },
  { id: 1, name: "Persons", link: "/person" },
  { id: 2, name: "Tags", link: "/tag" },
  { id: 8, name: "Unlinked Tags", link: "/unlink-tag" },
  { id: 3, name: "Devices", link: "/device-list" },
  { id: 4, name: "Daily Events", link: "/daily-event" },
  { id: 5, name: "Events", link: "/event" },
  // { id: 9, name: "C66 Device", link: "#" },
  { id: 6, name: "Caregivers", link: "/caregiver" },
  // { id: 6, name: "Device List", link: "/device-list" },
  { id: 7, name: "Setting", link: "/setting/event-type" },
  // { id: 8, name: "Caregiver", link: "#" },
];

export const careGivermenuItems = [
  { id: 0, name: "Stakeholder", link: "/stakeholder" },
  { id: 1, name: "Assign Person", link: "/assignperson" },
];

export const settingSubItems = [
  { id: 0, name: "Event Type", link: "/setting/event-type" },
  { id: 8, name: "Daily Event Type", link: "/setting/daily-event-type" },
  { id: 1, name: "Time Message", link: "/setting/time-message" },
  { id: 2, name: "Location Message", link: "/setting/location-message" },
  { id: 3, name: "Level", link: "/setting/level" },
  { id: 4, name: "Location Type", link: "/setting/location-type" },
  { id: 9, name: "Sub Location Type", link: "/setting/sub-location-type" },
  // { id: 4, name: "Permission", link: "javascript:" },
  { id: 5, name: "Relation", link: "/setting/relation" },
  // {
  //   id: 6,
  //   name: "Clone MySQL to SQLite",
  //   link: "javascript:",
  // },
  // {
  //   id: 7,
  //   name: "Clone Log Table SQLite to MySQL",
  //   link: "javascript:",
  // },
];
