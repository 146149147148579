import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import style from "../CareGiver/CareGiver.module.css";
import Loader from "../LoaderComponent/LoaderComponent";
import add_icon from "../../Assests/Images/icon_add.png";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Pagination from "../CustomComponent/CustomPagination/Pagination";
import Actionicon from "../../Assests/Images/ActionIcon.png";
import {
  Row,
  Col,
  Container,
  Table,
  Image,
  Button,
  Modal,
  Form,
} from "react-bootstrap";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Toster/Toster";
import { clearUserState } from "../../redux/slices/userSlice";
import styles from "../../Components/Settings/Level/Level.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import deleteImage from "../../Assests/Images/delete_image.png";
import moment from "moment";
import { setHours } from "date-fns";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
function AssignPerson() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [pageNo, setPageNo] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 10;
  const [addedAssignPersonListCount, setAddedAssignPersonListCount] =
    useState(0);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // getAddedCareGiverList(pageNumber, searchQuery);
  };
  const [assignPersonList, setAssignPersonList] = useState([]);
  const tdAlignClass = { flex: 1, alignContent: "center" };
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = () => setShowDeleteModal(true);
  const [careGiverDeleteId, setCareGiverDelete] = useState();

  const [showDelete, setShowdelete] = useState(false);
  const handleCloseDelete = () => setShowdelete(false);
  const handleShowDelete = () => setShowdelete(true);
  const [showModal2, setShowModal2] = useState(false);

  const handleCloseModal2 = () => setShowModal2(false);

  // Fetch the assigned person's schedule when opening the modal
  const handleShowModal2 = (assign_id) => {
    setAssignSeniorId(assign_id);
    setShowModal2(true);
    handelGetData(assign_id);
  };

  const [hours, setHours] = useState([]);
  const [assignSeniorid, setAssignSeniorId] = useState();
  const [showSheduleList, setShowSheduleList] = useState([]);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const result =
      hours.length > 0 &&
      daysOfWeek.map((day) => {
        // Find if there's an input for the current day
        const dayInput = hours.find(
          (d) => d.days.toLowerCase() === day.toLowerCase()
        );

        if (dayInput) {
          return {
            days: day,
            is_available: dayInput.is_available,
            time_slots: dayInput.time_slots,
          };
        } else {
          return {
            days: day,
            is_available: "0", // Not available
            time_slots: [], // Empty time slots
          };
        }
      });
    setShowSheduleList(result);
  }, [hours]);

  useEffect(() => {
    getAssignPersonList(currentPage, "");
  }, []);
  const getAssignPersonList = async (pageNo, searchTerm) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.getAssignPersonList(
        pageNo,
        searchTerm,
        token
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];

      if (res?.code === STATUS_CODE.SUCCESS) {
        const assignPersonList = res.data.assignedSeniors;

        setAssignPersonList(assignPersonList);
        setAddedAssignPersonListCount(res.data.count);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching device list:", error);
    }
  };

  // Fetch schedule details from API
  const handelGetData = (assignSeniorid) => {
    setIsLoading(true);
    IndependicareApi.getAssignPersonHours(token, assignSeniorid).then(
      (response) => {
        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("session expired"), "error");
          navigate("/signin");
        } else {
          setHours(response?.data?.scheduleDetails?.schedule); // Set the schedule data
          setIsLoading(false);
        }
      }
    );
  };
  return (
    <div className="d-flex flex-column justify-content-between w-100 mt-3">
      <div>
        {isLoading && <Loader />}
        <Container>
          <div className="setTableFromBottom">
            {assignPersonList && assignPersonList.length > 0 ? (
              <Table className="customTable" striped hover responsive>
                <thead className="tableHeadProperty">
                  <tr>
                    <th>{t("NAME")}</th>
                    <th>{t("RELATION")}</th>
                    <th>{t("LEVEL")}</th>
                    <th className={style.permission}>{t("PERMISSION")}</th>
                    <th className={style.permission}>{t("WORKING_HOURS")}</th>
                  </tr>
                </thead>
                <tbody>
                  {assignPersonList.map((item, index) => (
                    <tr key={index}>
                      <td style={tdAlignClass} className="tdtext">
                        {item?.person_name}
                      </td>
                      <td style={tdAlignClass} className="tdtext">
                        {item.relation}
                      </td>
                      <td style={tdAlignClass} className="tdtext">
                        {item.level}
                      </td>
                      <td
                        style={tdAlignClass}
                        className="tdtext caregiverPermission"
                      >
                        {item.module_names}
                      </td>
                      <td style={tdAlignClass} className="tdtext">
                        <p
                          className={style.viewHour}
                          onClick={() => handleShowModal2(item.assign_id)}
                        >
                          View
                        </p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className={style.noRecord}>
                <p>{t("NO_RECORD_FOUND")}</p>
              </div>
            )}
          </div>
        </Container>
      </div>

      <Pagination
        count={addedAssignPersonListCount}
        pageLimit={pageLimit}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />

      {/*=========AssignPerson-delete-Modal==============*/}
      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseDelete}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("SURE_DELETE_ASSIGN_PERSON")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              // Leveldelete(levelId);
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>

      {/*=========Working Hours Modal==============*/}
      <Modal
        show={showModal2}
        onHide={handleCloseModal2}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className={style.hourModal}>
            <p>{t("Working Hours")}</p>
            <OverlayTrigger
              placement="top" // You can adjust the position (top, bottom, left, right)
              overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
            >
              <CancelIcon
                className="closeIconProperty"
                onClick={handleCloseModal2}
              />
            </OverlayTrigger>
            {/* <CancelIcon
              className="closeIconProperty"
              onClick={handleCloseModal2}
            /> */}
          </div>
        </Modal.Header>
        <Modal.Body>
          {showSheduleList.length > 0 ? (
            <div>
              {showSheduleList.map((schedule, index) => (
                <div key={index} className={style.hourModalText}>
                  <p>
                    {schedule.days.charAt(0).toUpperCase() +
                      schedule.days.slice(1)}{" "}
                    :
                  </p>
                  {schedule.time_slots.length > 0 ? (
                    schedule.time_slots.map((slot, idx) => (
                      <p key={idx}>
                        {moment(slot.start_time, "HH:mm:ss").format("hh:mm A")}{" "}
                        To {moment(slot.end_time, "HH:mm:ss").format("hh:mm A")}
                      </p>
                    ))
                  ) : (
                    <p className={style.closeText}>Closed</p>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p>{t("")}</p>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default AssignPerson;
