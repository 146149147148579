import React, { useEffect, useRef, useState } from "react";
import styles from "./CareGiverDetails.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import IndependicareApi from "../../Helpers/Api";
import Toster from "../../Toster/Toster";
import { Container, Button, Modal } from "react-bootstrap";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import { clearUserState } from "../../redux/slices/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../LoaderComponent/LoaderComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignSenior from "../CareGiver/AssignSenior";
import DeleteIcon from "@mui/icons-material/Delete";
import { Image } from "react-bootstrap";
import deleteImage from "../../Assests/Images/delete_image.png";
import useDebounce from "../../Helpers/Debounce";
import { LIST_TYPE } from "../../Utils/Constant";
import Confirm from "../../Common/Confirm";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
function CareGiverDetails() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const states = location.state || {}; // Extracting params
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [careGiverDetailsData, setCareGiverDetailsData] = useState();
  const [personListToShow, setPersonListToShow] = useState([]);
  const [selectedPersonIndex, setSelectedPersonIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [personList, setPersonList] = useState([]);
  const [relationList, setRelationList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [selectRelation, setSelectRelation] = useState("");
  const [selectPerson, setSelectPerson] = useState("");
  const [selectLevel, setSelectLevel] = useState("");
  const [selectPermission, setSelectPermission] = useState([]);
  const [dutyHoursData, setDutyHoursData] = useState([]);
  const [removeAssignPersonId, setRemoveAssignPersonId] = useState("");
  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const [showAssignCareGiverModal, setShowAssignCareGiverModal] =
    useState(false);
  const handleCloseModalAssignCareGiver = () => {
    setShowAssignCareGiverModal(false);
  };
  const handleShowModalAssignCareGiver = () => {
    setShowAssignCareGiverModal(true);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  useEffect(() => {
    getCareGiverDetails();
    //meta apis
    getPersonList(1, "");
    getRelationList();
    getPermissionList();
    getLevelList();
  }, []);
  const handleSelect = (index) => {
    const updatedPersons = personListToShow.map((person, i) => ({
      ...person,
      isSelected: i === index,
    }));
    setPersonListToShow(updatedPersons);
    setSelectedPersonIndex(index);
  };
  const getCareGiverDetails = async (activeIndex = 0) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.getCareGiverDetailsAPI(
        token,
        states.id
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        setCareGiverDetailsData(res.data.caregiverDetail);
        // Add the isSelected key to every object in the array
        const updatedPersonList = res.data.personList.map((person, index) => {
          return {
            ...person, // Keep the existing properties
            isSelected: index === activeIndex, // Set isSelected to true for the first item, false for the others
          };
        });
        setPersonListToShow(updatedPersonList);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };

  const [personOption, setPersonOption] = useState([]);
  const [showPerson, setShowPerson] = useState(false);
  const [selectedPersonName, setSelectedPersonName] = useState(""); // Correct function definition
  const [selectedPersonId, setSelectedPersonId] = useState(null); // Correct function definition
  const [currentPageForPerson, setCurrentPageForPerson] = useState(1);
  const [isLoadMore, setIsLoadMore] = useState(false);

  const listInnerRef = useRef();

  const handleClosePerson = () => {
    setShowPerson(false);
  };

  const handleShowPerson = () => {
    setShowPerson(true);
    getPersonList(1, ""); // Load the initial list when modal opens
  };
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchPerson = useDebounce((term) => {
    getPersonList(1, term);
  }, 500);

  const getPersonList = (page, search) => {
    try {
      setIsLoading(true);
      IndependicareApi.getPersonListAssignPerson(
        token,
        page,
        "",
        search,
        LIST_TYPE.ACTIVE
      ).then((res) => {
        setIsLoading(false);
        if (res?.code === STATUS_CODE.SUCCESS) {
          const newPersons = res?.data?.person_list;
          if (page === 1) {
            setPersonOption(newPersons);
          } else {
            setPersonOption((prevPersons) =>
              Array.isArray(prevPersons)
                ? [...prevPersons, ...newPersons]
                : newPersons
            );
          }
          setCurrentPageForPerson(page);
          setIsLoadMore(res?.data?.loadMore);
        } else if (
          res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          res?.data?.code === STATUS_CODE.INACTIVE_USER
        ) {
          dispatch(clearUserState());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/signin");
        } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
          Toster(res?.data?.message, "error");
        } else {
          Toster(t(STATUS_MSG[res?.data?.code]), "error");
        }
      });
    } catch (error) {
      console.log(t("ERROR_FETCHING_PERSON_LIST"), error);
    }
  };

  const handleScroll = () => {
    if (!isLoadMore) return;
    if (listInnerRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        onPageChangePerson(currentPageForPerson + 1);
      }
    }
  };

  const onPageChangePerson = (pageNumber) => {
    getPersonList(pageNumber, searchQuery);
  };

  const handleSubmitPerson = () => {
    // if (tempPersonId == "") {
    //   Toster(t("PLEASE_SELECT_PERSON"), "error");
    //   return;
    // }
    // setSelectedPersonId(tempPersonId);
    // setSelectedPerson(tempPersonName);
    // handleClosePerson();
  };

  const getRelationList = async () => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.fetchRelation(token);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const transformedRelationListArray = res.data.relation.map((item) => ({
          id: item.id,
          name: item.name,
        }));
        setRelationList(transformedRelationListArray);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const getPermissionList = async () => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.fetchPermission(token);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const transformedPermissionListArray = res.data.permission_list.map(
          (item) => ({
            id: item.id,
            name: item.module_name,
          })
        );
        setPermissionList(transformedPermissionListArray);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };
  const getLevelList = async () => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.fetchLevel(token);
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        const transformedLevelListArray = res.data.level.map((item) => ({
          id: item.id,
          name: item.level,
        }));
        setLevelList(transformedLevelListArray);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error adding care giver", error);
    }
  };

  const updateAssignSeniorApi = async (formData, senior_id) => {
    try {
      setIsLoading(true);
      const res = await IndependicareApi.updateAssignSenior(
        formData,
        token,
        senior_id
      );
      setIsLoading(false);
      const responseCode = STATUS_MSG[res?.data?.code];
      if (res?.code === STATUS_CODE.SUCCESS) {
        Toster(t("ASSIGN_PERSON_UPDATED_SUCCESSFULLY"), "success");
        handleCloseModalAssignCareGiver();
        getCareGiverDetails(selectedPersonIndex);
      } else if (
        res?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        res?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (res?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      setIsLoading(false);
      // reGiverDet;
      console.error("Error adding care giver", error);
    }
  };

  function capitalizeName(name) {
    if (name) {
      return name
        .toLowerCase() // convert the whole string to lowercase first
        .split(" ") // split the string by spaces
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize first letter
        .join(" "); // join back the words
    }
  }
  const PersonDetails = ({ label, value }) => {
    return (
      <div
        style={{
          height: "auto",
          display: "flex",
          flexDirection: "row",
          flex: 1,
          marginBottom: 15,
        }}
      >
        <div
          style={{
            whiteSpace: "nowrap",
            color: "#828282",
            fontSize: 18,
            fontWeight: 500,
            marginRight: 10,
          }}
        >
          {label}
          {" :"}
        </div>
        <div
          style={{
            color: value == t("CLOSED") ? "#FF0000" : "#000000",
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          {" "}
          {value}
        </div>
      </div>
    );
  };
  const validateObject = (obj) => {
    // Define the required keys
    const requiredKeys = [
      "person_id",
      "relation_id",
      "permission_id",
      "level_id",
      "schedule_data",
    ];

    // Check for missing or falsy values for required keys
    for (const key of requiredKeys) {
      if (!obj.hasOwnProperty(key) || !obj[key]) {
        let messageKey;

        // Determine the appropriate message key based on the missing key
        switch (key) {
          case "person_id":
            messageKey = "PLEASE_SELECT_PERSON";
            break;
          case "relation_id":
            messageKey = "PLEASE_SELECT_RELATION";
            break;
          case "permission_id":
            messageKey = "PLEASE_SELECT_PERMISSION";
            break;
          case "level_id":
            messageKey = "PLEASE_SELECT_LEVEL";
            break;
          case "schedule_data":
            messageKey = "PLEASE_SELECT_DUTY_HOURS";
            break;
          default:
            messageKey = "HEADER_IS_MISSING";
            break;
        }

        Toster(t(messageKey), "error");
        return false;
      }
    }

    // Check if schedule_data has a length greater than 0
    if (!Array.isArray(obj.schedule_data) || obj.schedule_data.length === 0) {
      Toster(t("PLEASE_SELECT_DUTY_HOURS"), "error");
      return false;
    }

    // If all checks passed
    return true;
  };
  // Function to convert 24-hour time to 12-hour time format
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    let hour12 = hour % 12 || 12; // convert 24hr to 12hr
    const ampm = hour >= 12 ? "PM" : "AM";
    return `${hour12}:${minute} ${ampm}`;
  };

  // Function to transform the schedule array and ensure all 7 days are present
  const transformSchedule = (schedule) => {
    // List of all 7 days
    const allDays = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    // Create the transformed array with all 7 days
    return allDays.map((day) => {
      // Find if the current day is in the schedule array
      const daySchedule = schedule.find((item) => item.days === day);

      // If the day is found and available, return its details
      if (daySchedule && daySchedule.is_available === "1") {
        return {
          days: day.charAt(0).toUpperCase() + day.slice(1),
          is_available: "1",
          from: formatTime(daySchedule.time_slots[0].start_time),
          to: formatTime(daySchedule.time_slots[0].end_time),
        };
      }
      // If the day is not found or is unavailable, return with default values
      else {
        return {
          days: day.charAt(0).toUpperCase() + day.slice(1),
          is_available: "0",
          from: null,
          to: null,
        };
      }
    });
  };

  const assignPersonRemove = (id) => {
    setRemoveAssignPersonId(id);
    // setShowModal(true);
    setTitleText(t("ARE_YOU_SURE_DELETE_ASSIGN_PERSON"));
    setIsDeleting(true);
    setIsShowConfirm(true);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    isDeleting && confirmDeletePerson();
  };

  const confirmDeletePerson = async () => {
    setIsLoading(true);
    try {
      const response = await IndependicareApi.deleteSeniorPerson(
        removeAssignPersonId,
        token
      );
      const responseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        // getCareGiverDetails();
        Toster(t("SENIOR_PERSON_DELETED_SUCCESSFULLY"), "success");
        getCareGiverDetails();
        handleSelect(0);
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(t(responseCode), "error");
      } else {
        Toster(t(responseCode), "error");
      }
    } catch (error) {
      console.error("Error deleting senior person:", error);
      Toster(t("ERROR_DELETING_SENIOR_PERSON"), "error");
    } finally {
      setIsLoading(false);
      // setShowModal(false);
      setIsShowConfirm(false);
    }
  };
  return (
    <>
      <div className={styles.tbl}>
        {isLoading && <Loader />}
        <Modal
          show={showAssignCareGiverModal}
          onHide={handleCloseModalAssignCareGiver}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="d-flex justify-content-between">
            <Modal.Title className="setModelTitleProperty">
              {t("EDIT_ASSIGN_SENIOR")}
            </Modal.Title>

            <OverlayTrigger
              placement="top" // You can adjust the position (top, bottom, left, right)
              overlay={<Tooltip id="cancel-tooltip">{t("CLOSE")}</Tooltip>} // Customize your tooltip message
            >
              <CancelIcon
                className="closeIconProperty"
                onClick={handleCloseModalAssignCareGiver}
              />
            </OverlayTrigger>
          </Modal.Header>
          <Modal.Body>
            <AssignSenior
              personOption={personOption}
              listInnerRef={listInnerRef}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              debouncedSearchPerson={debouncedSearchPerson}
              handleScroll={handleScroll}
              handleShowPerson={handleShowPerson}
              handleClosePerson={handleClosePerson}
              selectedPersonName={selectedPersonName}
              selectedPersonId={selectedPersonId}
              setSelectedPersonName={setSelectedPersonName} // Ensure prop is passed correctly
              setSelectedPersonId={setSelectedPersonId} // Ensure prop is passed correctly
              showPerson={showPerson}
              //
              // personList={personList}
              relationList={relationList}
              permissionList={permissionList}
              levelList={levelList}
              selectRelation={selectRelation}
              setSelectRelation={setSelectRelation}
              // selectPerson={selectPerson}
              // setSelectPerson={setSelectPerson}
              selectLevel={selectLevel}
              setSelectLevel={setSelectLevel}
              selectPermission={selectPermission}
              setSelectPermission={setSelectPermission}
              dutyHoursData={dutyHoursData}
              setDutyHoursData={setDutyHoursData}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="AddSiteSubmitButton"
              onClick={() => {
                const selectPermissionValues = selectPermission
                  ? selectPermission.map((option) => option.value)
                  : [];
                const formData = {
                  person_id: selectedPersonId,
                  relation_id: selectRelation,
                  permission_id: selectPermissionValues.join(", "),
                  level_id: selectLevel,
                  schedule_data: dutyHoursData,
                };
                const isValid = validateObject(formData);
                if (isValid) {
                  updateAssignSeniorApi(
                    formData,
                    personListToShow[selectedPersonIndex]?.assign_id
                  );
                }
              }}
            >
              {t("UPDATE")}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className={styles.backArrowSec}>
          <Link to="/caregiver">
            <div className={styles.backArrow}>
              <MdArrowBack />
              <span>{t("CAREGIVER_DETAILS")}</span>
            </div>
          </Link>
        </div>
        {careGiverDetailsData && (
          <Container>
            <div className={styles.infoSection}>
              <h3>{t("BASIC_INFO")}</h3>
              <div className={`${styles.mainInfo_sec} ${styles.borderTop}`}>
                <div className={styles.userInfo}>
                  <h4>{t("NAME")}</h4>
                  <p>{capitalizeName(careGiverDetailsData?.name)}</p>
                </div>
                <div className={styles.userInfo1}>
                  <h4>{t("EMAIL")}</h4>
                  <p className={styles.emailText}>
                    {capitalizeName(careGiverDetailsData?.email)}
                  </p>
                </div>
              </div>
              <div className={`${styles.mainInfo_sec} ${styles.borderBottom}`}>
                <div className={styles.userInfo}>
                  <h4>{t("CONTACT_NUMBER")}</h4>
                  <p>
                    {careGiverDetailsData?.dial_code}{" "}
                    {careGiverDetailsData?.phone_number}
                  </p>
                </div>
                <div className={styles.userInfo1}>
                  <h4>{t("ADDRESS")}</h4>
                  <p>{careGiverDetailsData?.street_address}</p>
                </div>
              </div>
            </div>

            {personListToShow.length > 0 && (
              <>
                <div
                  style={{
                    backgroundColor: "#F1F1F1",
                    width: "100%",
                    border: "1px solid #D4D4D4",
                    borderRadius: "4px",
                    marginTop: 20,
                    marginBottom: 30,
                    padding: 5,
                    whiteSpace: "nowrap",
                    overflowX: "auto",
                    overflowY: "hidden",
                  }}
                  role="group"
                  aria-label="Person Selector"
                >
                  {personListToShow.map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      style={{
                        backgroundColor: item.isSelected ? "#1D4488" : null,
                        border: "0px solid #1D4488",
                        borderRadius: "4px",
                        color: item.isSelected ? "#ffffff" : "#828282",
                        fontSize: 16,
                        fontWeight: 500,
                        paddingRight: 30,
                        paddingLeft: 30,
                        paddingTop: 5,
                        paddingBottom: 5,
                      }}
                      onClick={() => handleSelect(index)}
                    >
                      {"Person " + (index + 1)}
                    </button>
                  ))}
                </div>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 500,
                  }}
                  className={styles.stakeholderDetail}
                >
                  <div
                    style={{
                      height: 100,
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <PersonDetails
                      label={t("SENIORS")}
                      value={capitalizeName(
                        personListToShow[selectedPersonIndex]?.person_name
                      )}
                    />

                    <PersonDetails
                      label={t("RELATION")}
                      value={capitalizeName(
                        personListToShow[selectedPersonIndex]?.relation_name
                      )}
                    />
                    <PersonDetails
                      label={t("PERMISSION")}
                      value={personListToShow[selectedPersonIndex]?.permissions
                        .map((permission) => permission.permission_name)
                        .join(", ")}
                    />
                    <PersonDetails
                      label={t("LEVEL")}
                      value={personListToShow[selectedPersonIndex]?.level}
                    />
                    <PersonDetails label={t("DUTY_HOURS")} value={""} />

                    {transformSchedule(
                      personListToShow[selectedPersonIndex]?.schedule
                    ).map((item) => {
                      return (
                        <PersonDetails
                          label={item.days}
                          value={
                            item.is_available == 1
                              ? item.from + t(" TO ") + item.to
                              : t("CLOSED")
                          }
                        />
                      );
                    })}
                  </div>
                  <div className={styles.delUpdateIcons}>
                    <div
                      onClick={async () => {
                        await setSelectedPersonName(
                          personListToShow[selectedPersonIndex]?.person_name
                        );
                        await setSelectedPersonId(
                          personListToShow[selectedPersonIndex]?.person_id
                        );
                        await setSelectRelation(
                          personListToShow[selectedPersonIndex]?.relation_id
                        );
                        await setSelectLevel(
                          personListToShow[selectedPersonIndex]?.level_id
                        );
                        await setSelectPermission(
                          personListToShow[
                            selectedPersonIndex
                          ]?.permissions.map((permission) => ({
                            value: permission.permission_id,
                            label: permission.permission_name,
                          }))
                        );
                        await setDutyHoursData(
                          personListToShow[selectedPersonIndex]?.schedule
                        );
                        handleShowModalAssignCareGiver();
                      }}
                      style={{
                        width: 120,
                        height: 40,
                        backgroundColor: "#5DA128",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "6px",
                        color: "#ffffff",
                        fontWeight: 600,
                        fontSize: 17,
                      }}
                    >
                      {t("EDIT_PERSON")}
                    </div>
                    <div
                      style={{
                        width: 120,
                        height: 40,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                      className={styles.deleteImageIcon}
                      onClick={() =>
                        assignPersonRemove(
                          personListToShow[selectedPersonIndex]?.assign_id
                        )
                      }
                    >
                      <DeleteIcon style={{ color: "red" }} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </Container>
        )}
      </div>
      {/* delete model form */}

      {/* const assignPersonRemove = (id) => {
    setRemoveAssignPersonId(id);
    setShowModal(true);
  }; */}
      <Confirm
        isShowConfirm={isShowConfirm}
        setIsShowConfirm={setIsShowConfirm}
        titleText={titleText}
        setTitleText={setTitleText}
        isDeleting={isDeleting}
        setIsDeleting={setIsDeleting}
        isLoading={isLoading}
        handleConfirm={handleConfirm}
      />

      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_DELETE_ASSIGN_PERSON")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={confirmDeletePerson}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default CareGiverDetails;
