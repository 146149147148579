import React, { useEffect, useState } from "react";
import { Col, Container, Row, Modal, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdArrowBack } from "react-icons/md";
import styles from "../../Components/AddPerson/PersonDetail.module.css";
import defaultImage from "../../Assests/Images/avtaarImage.jpg";
import { Image } from "react-bootstrap";
import { clearUserState, getPerson } from "../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import Toster from "../../Toster/Toster";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import IndependicareApi from "../../Helpers/Api";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Loader from "../LoaderComponent/LoaderComponent";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import PlayAudioBtn from "../../Assests/Images/PlayAudioBtn.png";
import ReactAudioPlayer from "react-audio-player";

function PersonDetail() {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [personData, setPersonData] = useState("");
  const dispatch = useDispatch();
  const [assigncaregiver, setAssignCaregiver] = useState([]);

  const [showModal2, setShowModal2] = useState(false);
  const [selectedCaregiver, setSelectedCaregiver] = useState(null);

  const [showAudioModal, setShowAudioModal] = useState(false);
  const [currentAudio, setCurrentAudio] = useState(null);

  const [showAudioModal1, setShowAudioModal1] = useState(false);
  const [currentAudio1, setCurrentAudio1] = useState(null);
  const [audioType, setAudioType] = useState(null);

  // const handleCloseModal2 = () => setShowModal2(false);
  // const handleShowModal2 = () => setShowModal2(true);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = (caregiver) => {
    setSelectedCaregiver(caregiver);
    setShowModal2(true);
  };
  // const handleCloseAudioModal = () => setShowAudioModal(false);
  const handleCloseAudioModal1 = () => setShowAudioModal1(false);
  const personId = location.state.person_id;

  const token = useSelector((state) => state.user.userToken);
  const usertype = useSelector((state) => state.user.userType);

  const navigate = useNavigate();

  const handleGetProfile = () => {
    setIsLoading(true);
    const params = {
      token: token,
      id: personId,
    };
    dispatch(getPerson(params)).then((res) => {
      setIsLoading(false);
      var response = res.payload;
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        setPersonData(response?.data);
        setAssignCaregiver(response?.data?.assignCaregiver);
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    handleGetProfile();
  }, []);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // const playAudio1 = (audioUrl) => {
  //   setCurrentAudio(audioUrl);
  //   setShowAudioModal(true);
  // };

  const playAudio1 = (audioUrl, type) => {
    setCurrentAudio(audioUrl); // Set the current audio URL
    setAudioType(type); // Set the type of audio (alert_tone or prompt_intro)
    setShowAudioModal(true); // Show the modal
  };

  const playAudio2 = (audioUrl) => {
    setCurrentAudio1(audioUrl);
    setShowAudioModal1(true);
  };
  const handleCloseAudioModal = () => {
    setShowAudioModal(false);
    setCurrentAudio(null);
    setAudioType(null); // Reset the audio type when modal closes
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.tbl}>
        <div className={styles.backArrowSec}>
          <Link to="/person">
            <div className={styles.backArrow}>
              <MdArrowBack />
              <span>{t("PERSON_DETAILS")}</span>
            </div>
          </Link>
        </div>

        <Container>
          <div className={styles.infoSection}>
            <Row>
              <div className="col-lg-4">
                <h3>{t("BASIC_INFO")}</h3>
                <div className={styles.personInfo}>
                  <div className={styles.personImgdiv}>
                    <div className={styles.personImg}>
                      {/* <Image src={defaultImage}></Image> */}
                      <Image
                        src={
                          personData.profile_img
                            ? personData.profile_img
                            : { defaultImage }
                        }
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className={styles.personName}>
                    <p>{personData?.person_name}</p>
                  </div>
                  <div className={styles.personPrompt}>
                    <h4>{t("PROMPT_INTRO_TEXT")}</h4>
                    <p>
                      {personData?.prompt_intro_text
                        ? personData?.prompt_intro_text
                        : "NA"}
                    </p>
                  </div>

                  <div className={styles.personLang}>
                    <h4>{t("LANGUAGE_TO_USE")}</h4>
                    <p>{personData?.language}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <h3>{t("OTHER_INFO")}</h3>
                <div className={`${styles.personInfo} ${styles.basicInfo}`}>
                  <ul>
                    <li>
                      <p>{t("ALERT_TONE_AUDIO")}</p> :
                      {/* {personData?.alert_tone_audio ? ( */}
                      <span>
                        {/* <Image
                            src={PlayAudioBtn}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              playAudio1(
                                personData?.alert_tone_audio,
                                "alert_tone"
                              )
                            }
                          /> */}
                        {personData.is_enable_default_tone == 1 ||
                        personData.alert_tone_audio == null ? (
                          <Image
                            src={PlayAudioBtn}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              playAudio1(personData.default_tone, "alert_tone")
                            }
                          />
                        ) : (
                          <Image
                            src={PlayAudioBtn}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              playAudio1(
                                personData.alert_tone_audio,
                                "alert_tone"
                              )
                            }
                          />
                        )}
                      </span>
                      {/* ) : (
                        <span>NA</span>
                      )} */}
                    </li>

                    <li>
                      <p>{t("PROMPT_INTRO_AUDIO")}</p> :
                      {personData?.prompt_intro_audio ? (
                        <span>
                          <Image
                            src={PlayAudioBtn}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              playAudio1(
                                personData?.prompt_intro_audio,
                                "prompt_intro"
                              )
                            }
                          />
                        </span>
                      ) : (
                        <span>NA</span>
                      )}
                    </li>

                    <li>
                      <p>{t("PROMPT_OPTION")}</p> :
                      <span>{personData?.prompt_option}</span>
                    </li>

                    {/* {personData?.prompt_option === "tts-prompt" && (
                      <li>
                        <p>{t("TTS_PROMPT")}</p> :
                        <span>{personData.tts_prompt || "none"}</span>
                      </li>
                    )} */}
                    {/* Conditionally render Audio Prompt if the prompt option is Audio */}
                    {/* {personData?.prompt_option === "audio-prompt" && (
                      <li>
                        <p>{t("AUDIO_PROMPT")}</p> :
                        <span>
                          {personData?.audio_prompt ? (
                            <Image
                              src={PlayAudioBtn}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                playAudio2(personData?.audio_prompt)
                              }
                            />
                          ) : (
                            "none"
                          )}
                        </span>
                      </li>
                    )} */}
                    <li>
                      <p>{t("NOTES")}</p> :{" "}
                      <span>
                        {personData?.notes ? personData?.notes : "NA"}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </Row>
            {/*============Assign-Caregiver-List================*/}
            <section>
              <div className={styles.caregiverList}>
                <h3>{t("ASSIGN_CAREGIVER_LIST")}</h3>
                <p>{t("CALENDAR_VIEW")}</p>
              </div>
              {assigncaregiver.map((item, index) => (
                <div className={styles.listSection} key={index}>
                  <Row className={styles.alignTab}>
                    <div className="col-lg-4">
                      <div className={styles.personBasicInfo}>
                        <ul>
                          <li>
                            <div className={styles.infoPerson}>
                              <p>{t("NAME")} :</p>
                              <span>{item?.name}</span>
                            </div>
                          </li>
                          <li>
                            <div className={styles.emailPerson}>
                              <p>{t("EMAIL_ID")} :</p>
                              <span>{item?.email}</span>
                            </div>
                          </li>
                          <li>
                            <div className={styles.relationInfo}>
                              <p>{t("RELATION")} :</p>
                              <span>{item?.relation}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className={styles.personBasicInfo}>
                        <ul>
                          <li>
                            <div className={styles.infoPerson}>
                              <p>{t("PERMISSION")} :</p>
                              <span>{item?.permission}</span>
                            </div>
                          </li>
                          <li>
                            <div className={styles.emailPerson}>
                              <p>{t("LEVEL")} :</p>
                              <span>{item?.level}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="modelButtonPropwerty">
                        <Button
                          type="button"
                          className="modalTextButton"
                          onClick={() => handleShowModal2(item)}
                        >
                          {t("WORKING_HOURS")}
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              ))}
            </section>
          </div>
        </Container>
      </div>
      {/*=========Working Hours Modal==============*/}
      <Modal show={showModal2} onHide={handleCloseModal2}>
        <Modal.Header className="d-flex justify-content-between">
          {/* <p className={styles.Eventproperty}>Assign caregiver</p> */}
          <p className={styles.nameCargiver}>
            {selectedCaregiver ? selectedCaregiver.name : ""}
          </p>
          {/* {selectedCaregiver ? selectedCaregiver.name : ""} */}
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal2}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            {selectedCaregiver && (
              <>
                <p className={styles.dutyHours}>{t("DUTY_HOURS")} :</p>
                {daysOfWeek.map((day, dayIndex) => {
                  const scheduleItem = JSON.parse(
                    selectedCaregiver.schedule
                  ).find(
                    (item) => item.days.toLowerCase() === day.toLowerCase()
                  );

                  return (
                    <div key={dayIndex} className={styles.timeSlot}>
                      <p>{day}</p>
                      {scheduleItem && scheduleItem.time_slots.length > 0 ? (
                        scheduleItem.time_slots.map((slot, slotIndex) => (
                          <p key={slotIndex}>
                            {`${moment(slot.start_time, "HH:mm:ss").format(
                              "h:mm A"
                            )} To ${moment(slot.end_time, "HH:mm:ss").format(
                              "h:mm A"
                            )}`}
                          </p>
                        ))
                      ) : (
                        <p className={styles.closedText}>{t("CLOSED")}</p>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/*===Prompt-intro-audio-modal===*/}
      <Modal show={showAudioModal} onHide={handleCloseAudioModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {/* <div className={styles.audioIntro}>
              <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
            </div> */}
            <div className={styles.audioIntro}>
              {/* Conditional rendering based on audioType */}
              {audioType === "alert_tone" ? (
                <h4>{t("ALERT_AUDIO_TONE")}</h4>
              ) : (
                <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
              )}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudio && (
            <ReactAudioPlayer
              src={currentAudio}
              autoPlay
              controls
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
      </Modal>
      {/*===audio-prompt-modal===*/}
      {/* <Modal show={showAudioModal1} onHide={handleCloseAudioModal1}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className={styles.audioIntro}>
              <h4>{t("PROMPT_INTRO_AUDIO")}</h4>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentAudio1 && (
            <ReactAudioPlayer
              src={currentAudio1}
              autoPlay
              controls
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default PersonDetail;
