import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Button,
  Modal,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Logo from "../Logo/Logo";
import Ellipse from "../../Assests/Images/Ellipse.png";
import style from "./Sidebar.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../redux/slices/userSlice";
import Footer from "../Footer/Footer";
import CustomButton from "../CustomComponent/CustomButton/CustomButton";
import { CustomPasswordInputField } from "../CustomComponent/CustomInputFields/CustomInputField";
import { CustomInputFields } from "../CustomComponent/CustomInputFields/CustomInputField";
import IndependicareApi from "../../Helpers/Api";
import Toster from "../../Toster/Toster";
import { userLogOut } from "../../redux/slices/userSlice";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Loader from "../LoaderComponent/LoaderComponent";
import { menuItems, careGivermenuItems, settingSubItems } from "./SidebarData";
import { clearUserState } from "../../redux/slices/userSlice";
import userDummmy from "../../Assests/Images/userDummy.jpeg";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../../Components/Settings/Eventtype/Event.module.css";
import deleteImage from "../../Assests/Images/delete_image.png";
import NotificationPage from "../Notification/NotificationPage";

function Sidebar() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.userToken);
  const usertype = useSelector((state) => state.user.userType);
  const profileData = useSelector((state) => state.user.profileData);
  const [sideBarList, setSideBarList] = useState(menuItems);

  useEffect(() => {
    if (currentUser.user_type == "caregiver") {
      setSideBarList(careGivermenuItems);
      // menuItems = [{ id: 0, name: "Stakeholder", link: "/stakeholder" }];
    }
  }, [currentUser.user_type]);

  const DropdownMenu = ({ items, handleCloseOffcanvas }) => (
    <ul className="dropdown settingDropdown">
      {items.map((subItem, subIndex) => (
        <li
          key={subIndex}
          className={
            subItem.link === pathname ? "settingActive" : "settingInactive"
          }
        >
          <Link to={subItem.link} onClick={handleCloseOffcanvas}>
            {subItem.name}
          </Link>
        </li>
      ))}
    </ul>
  );

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdownClick = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  if (currentUser.user_type == "caregiver") {
    // menuItems = [{ id: 0, name: "Stakeholder", link: "/stakeholder" }];
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation();
  const location = useLocation();

  const pathname = location.pathname;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState, setValue, reset } = useForm({
    mode: "onChange",
  });

  const [showModal2, setShowModal2] = useState(false);
  const handleCloseModal2 = () => setShowModal2(false);
  const handleShowModal2 = () => setShowModal2(true);

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleItemClick = (index) => {
    setActiveItem(index);
    setShowOffcanvas(false);
  };

  {
    /*===================LogOut====================================*/
  }
  const logouthandleFunction = () => {
    const data = {
      token: token,
      usertype: usertype,
    };
    setIsLoading(true);
    dispatch(userLogOut(data))
      .then((result) => {
        setIsLoading(false);
        if (userLogOut.fulfilled.match(result)) {
          navigate("/signin");
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  // const showProfile = () => {
  //   navigate("/site");
  // };
  {
    /*=================Change Password=========================*/
  }

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("old_password", data.password);
    formData.append("new_password", data.newPassword);
    formData.append("confirm_password", data.confirmPasswordProfile);
    formData.append("user_type", usertype);

    const userData = {
      formData: formData,
      token: token,
      usertype: usertype,
    };
    setIsLoading(true);

    IndependicareApi.changePassword(userData)
      .then(
        (response) => {
          setIsLoading(false);

          const ResponseCode = STATUS_MSG[response?.data.code];
          // if (
          //   response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          //   response?.data?.code === STATUS_CODE.INACTIVE_USER
          // ) {
          //   dispatch(clearUserState());
          //   Toster(t("session expired"), "error");
          //   navigate("/signin");
          // } else {
          if (response?.code == STATUS_CODE.SUCCESS) {
            Toster(t("PASSWORD_CHANGE_SUCCESSFULLY"), "success");
            setShow(false);
            reset();
          } else if (
            response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
            response?.data?.code === STATUS_CODE.INACTIVE_USER
          ) {
            dispatch(clearUserState());
            Toster(t("session expired"), "error");
            navigate("/signin");
          } else if (response?.data?.code === STATUS_CODE.VALIDATIONS_ERROR) {
            Toster(response?.data?.message, "error");
          } else {
            Toster(t(ResponseCode), "error");
          }
        }
        // }
      )
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleShowProfile = () => {
    navigate("/profile");
  };

  {
    /*===========End=============*/
  }

  {
    /*=========Delete-Account 22-08-24============*/
  }
  const AccountDelete = () => {
    setIsLoading(true);
    IndependicareApi.deleteAccount(token).then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data.code];
      if (response?.code == STATUS_CODE.SUCCESS) {
        Toster(t("ACCOUNT_DELETED_SUCCESSFULLY"), "success");
        setShowModal2(false);
        navigate("/signin");
      }
    });
  };

  const handleGetProfile = () => {
    setIsLoading(true);
    dispatch(getProfile(token)).then((res) => {
      setIsLoading(false);
      var response = res.payload;
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
      } else if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/signin");
      } else if (response?.data?.code == STATUS_CODE.VALIDATIONS_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    handleGetProfile();
  }, []);

  return (
    <div>
      {isLoading === true ? <Loader /> : ""}
      <div className="toggleButton">
        <Button
          className="offsetIcon"
          variant="light"
          onClick={handleShowOffcanvas}
        >
          ☰
        </Button>
      </div>
      <Offcanvas
        show={showOffcanvas}
        onHide={handleCloseOffcanvas}
        className={style.offSetScreen}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Logo />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <ul>
              {sideBarList.map((item, index) => (
                <div className="setListDivElement" key={index}>
                  <Link
                    to={item.link}
                    activeClassName="is-active"
                    className={`link ${
                      location.pathname === item.link ? "active" : ""
                    }`}
                  >
                    <li
                      className={`listItems ${
                        location.pathname === item.link ? "active" : ""
                      }`}
                      onClick={
                        item.name == "Setting"
                          ? handleDropdownClick
                          : handleCloseOffcanvas
                      }
                    >
                      {item.name}
                      <KeyboardArrowRightIcon
                        className={
                          location.pathname === item.link
                            ? "activeIcon"
                            : "unactiveIcon"
                        }
                      />
                    </li>
                  </Link>
                </div>
              ))}
            </ul>
            {isDropdownOpen && (
              <DropdownMenu
                items={settingSubItems}
                handleCloseOffcanvas={handleCloseOffcanvas}
              />
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div className={`mainSidebarParent ${style.mainSidebarParent}`}>
        <div className={`offSetScreen ${style.offSetScreen}`}>
          <div className="setLogoOnDashboard">
            <Logo />
          </div>
          <div className={style.sidePadding}>
            {/*=============updated work=========*/}
            <ul>
              {sideBarList.map((item, index) => (
                <div className="setListDivElement" key={index}>
                  {item.name === "Setting" ? (
                    <>
                      <div
                        className={`link ${
                          location.pathname.startsWith("/setting")
                            ? "active"
                            : ""
                        }`}
                        onClick={handleDropdownClick}
                      >
                        <Link
                          to={item.link}
                          className={`link ${
                            location.pathname === item.link ? "active" : ""
                          }`}
                        >
                          <li
                            className={`listItems ${
                              location.pathname.startsWith("/setting")
                                ? "active"
                                : ""
                            }`}
                          >
                            {item.name}
                            <KeyboardArrowRightIcon
                              className={
                                isDropdownOpen ? "activeIcon" : "unactiveIcon"
                              }
                            />
                          </li>
                        </Link>
                      </div>
                      {isDropdownOpen && (
                        <DropdownMenu items={settingSubItems} />
                      )}
                    </>
                  ) : (
                    <Link
                      to={item.link}
                      className={`link ${
                        location.pathname === item.link ? "active" : ""
                      }`}
                    >
                      <li
                        className={`listItems ${
                          location.pathname === item.link ? "active" : ""
                        }`}
                      >
                        {item.name}
                        <KeyboardArrowRightIcon
                          className={
                            location.pathname === item.link
                              ? "activeIcon"
                              : "unactiveIcon"
                          }
                        />
                      </li>
                    </Link>
                  )}
                </div>
              ))}
            </ul>
          </div>
        </div>
        <div className="mainSectionContent">
          <div className="headSectionIcon">
            <NotificationPage />
            <div>
              {/* <Image src={Ellipse} roundedCircle onClick={showProfile} className='setImageProperty' /> */}
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <Image
                    // src={Ellipse}
                    src={
                      profileData?.profile_img
                        ? profileData.profile_img
                        : userDummmy
                    }
                    roundedCircle
                    // onClick={showProfile}
                    className="setImageProperty"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="d-flex justify-content-between align-items-center bg-none"
                    onClick={handleShowProfile}
                  >
                    <span className="Profile">Profile</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex justify-content-between align-items-center bg-none"
                    onClick={handleShow}
                  >
                    <span className="logoutText">{t("CHANGE_PASSWORD")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex justify-content-between align-items-center bg-none"
                    onClick={() => {
                      handleShowModal2();
                    }}
                  >
                    <span className="logoutText">{t("DELETE_ACCOUNT")}</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="d-flex justify-content-between align-items-center bg-none"
                    onClick={logouthandleFunction}
                  >
                    <span className="logoutText">{t("LOGOUT")}</span>
                    <span className="logoutIcon">
                      <FiLogOut />
                    </span>
                  </Dropdown.Item>
                  {/* <Dropdown.Item
                    className="d-flex justify-content-between align-items-center bg-none"
                    onClick={() => {
                      handleShowModal2();
                    }}
                  >
                    <span className="logoutText">{t("DELETE_ACCOUNT")}</span>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* <div style={{ height: "100%", paddingBottom: "100px" }}> */}
          <div style={{ height: "100%" }}>
            <Outlet />
          </div>
        </div>
      </div>
      <Footer />

      {/*=============Profile-Change-Password==================*/}
      <Modal show={show} onHide={handleClose}>
        {isLoading === true ? <Loader /> : ""}
        <Modal.Header closeButton>
          <Modal.Title>{t("CHANGE_PASSWORD")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CustomInputFields
              type="password"
              PlaceHolder={t("ENTER_OLD_PASSWORD")}
              RegisterName="password"
              register={register}
              formState={formState}
              label={t("OLD_PASSWORD")}
            />
            <CustomPasswordInputField
              type="Password"
              PlaceHolder={t("ENTER_NEW_PASSWORD")}
              RegisterName="newPassword"
              register={register}
              formState={formState}
              onChange={(e) => {
                setValue("newPassword", e.target.value.replace(/\s/g, ""), {
                  shouldValidate: true,
                });
              }}
              label={t("NEW_PASSWORD")}
            />
            <CustomPasswordInputField
              type="Password"
              PlaceHolder={t("ENTER_CONFIRM_PASSWORD")}
              RegisterName="confirmPasswordProfile"
              register={register}
              formState={formState}
              label={t("CONFIRM_PASSWORD")}
              onChange={(e) => {
                setValue(
                  "confirmPasswordProfile",
                  e.target.value.replace(/\s/g, ""),
                  {
                    shouldValidate: true,
                  }
                );
              }}
            />

            <div className="buttonContent">
              <Row className="rowButtonContent">
                <Col
                  sm={12}
                  md={12}
                  lg={12}
                  className="d-flex justify-content-end setButton"
                >
                  <CustomButton
                    type="submit"
                    value={t("SUBMIT")}
                    className="buttonProperty"
                  />
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/*=========Delete-Account-Modal 22-08--24==============*/}
      <Modal show={showModal2} onHide={handleCloseModal2}>
        <Modal.Header className="d-flex justify-content-between">
          <p className={styles.Eventproperty}>{t("Alert")}</p>
          <CancelIcon
            className="closeIconProperty"
            onClick={handleCloseModal2}
          />
        </Modal.Header>
        <Modal.Body>
          <div className={styles.deleteClass}>
            <Image src={deleteImage} />

            <p>{t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_ACCOUNT")}</p>
          </div>

          <Button
            type="submit"
            className="AddSiteSubmitButton"
            onClick={() => {
              AccountDelete();
            }}
          >
            {t("OK")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default Sidebar;
