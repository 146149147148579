import { Container, Table } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTranslation } from "react-i18next";
import IndependicareApi from "../../Helpers/Api";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "../../Components/Settings/Relation/Relation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMessage";
import Toster from "../../Toster/Toster";
import { clearUserState } from "../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import UnlinkTagDeleteModal from "./UnlinkTagDeleteModal";
import Loader from "../LoaderComponent/LoaderComponent";

function UnlinkTag() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [unlinkTag, setUnlinktag] = useState([]);
  const [unlinkId, setUnlinkId] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    if (unlinkId) {
      setShowModal(true);
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const UnlinkGetData = () => {
    setIsLoading(true);
    IndependicareApi.unlinktagList(token).then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.INACTIVE_USER
      ) {
        dispatch(clearUserState());
        Toster(t("session expired"), "error");
        navigate("/signin");
      } else {
        setUnlinktag(response?.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    UnlinkGetData();
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      <div className="pt-3">
        <Container>
          <div className={styles.eventTable}>
            <Table className={styles.customTable} striped>
              <thead className={styles.tableHeadProperty}>
                <tr>
                  <th className={styles.EventalignLeft}>
                    {t("UNIQUE_TAG_ID")}
                  </th>
                  <th className={`${styles.eventAction} ${styles.alignRight}`}>
                    {t("ACTION")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {unlinkTag?.map((item, index) => (
                  <tr key={index}>
                    <td className={styles.alignLeft}>{item.tag_uniq_id}</td>
                    <td className={styles.alignRight}>
                      <Dropdown className={styles.setEventDropdown}>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="custom-dropdown-toggle p-0 border-0"
                        >
                          <MoreHorizIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="custom-dropdown-menu">
                          <div
                            className="d-flex justify-content-around dropdownTextProperty"
                            onClick={() => {
                              handleShowModal();
                              setUnlinkId(item.tag_uniq_id);
                            }}
                          >
                            <Dropdown.Item>
                              <span>{t("DELETE")}</span>
                              <DeleteOutlineIcon className="iconColor" />
                            </Dropdown.Item>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
        <div className={styles.noRecord}>
          <p>
            {!isLoading && unlinkTag.length == 0 ? t("NO_RECORD_FOUND") : ""}
          </p>
        </div>
      </div>

      {/*=========delete-Unlink-tag-Modal==============*/}

      <UnlinkTagDeleteModal
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        UnlinkGetData={UnlinkGetData}
        unlinkId={unlinkId}
      />
    </>
  );
}

export default UnlinkTag;
