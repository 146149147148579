import React from "react";
import Image from "react-bootstrap/Image";
import logoImg from "../../Assests/Images/IndependiCareLogo21.png";
import styles from "./Logo.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Logo() {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const handleLogoClick = () => {
    if (!isAuthenticated) {
      navigate("/");
    }
  };
  return (
    <div>
      <div onClick={() => handleLogoClick()}>
        <Image src={logoImg} className={styles.logoImg} />
      </div>
    </div>
  );
}

export default Logo;
